import { ContactsActionTypes } from "./types";

// common success
export const contactsApiResponseSuccess = (actionType, data) => ({
  type: ContactsActionTypes.API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});
// common error
export const contactsApiResponseError = (
  actionType,
  error
) => ({
  type: ContactsActionTypes.API_RESPONSE_ERROR,
  payload: { actionType, error },
});

export const getContacts = (filters) => ({
  type: ContactsActionTypes.GET_CONTACTS,
  payload: filters,
});

export const inviteContact = (data) => ({
  type: ContactsActionTypes.INVITE_CONTACT,
  payload: data,
});

export const resetContacts = (flag, value) => ({
  type: ContactsActionTypes.RESET_CONTACTS,
  payload: { flag, value },
});
