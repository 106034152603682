import { LayoutActionTypes } from "./types";

export const changeTab = (layoutMode) => ({
  type: 'dark',
  payload: layoutMode,
});

export const changelayoutMode = (layoutMode ) => ({
  type: 'light',
  payload: { layoutMode},
});
