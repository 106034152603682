import React, { useEffect, useState } from "react";
import classnames from "classnames";

// interface
import { UserTypes } from "../../../data/chat";

import { useDispatch, useSelector } from "react-redux";

import { setselectedChat,setchatUserDetails } from "../../../redux/apiCalls";


// component
import YoutubeChatuser from "./YoutubeChatuser";

import axios from "axios";

// interface FavouritesProps {
//   users: Array<UserTypes>;
//   selectedChat: string | number;
//   onSelectChat: (id: number | string) => void;
// }

const YoutubeChannel = ({ users,youtube, selectedChat,setSelectedChat, onSelectChat }) => {

  const { currentUser } = useSelector((state) => state.user);

  const dispatch = useDispatch();  

 const handleClick = (userId) => {
    console.log("FavouritesId",userId)
    setSelectedChat(userId);
    const data = { groupid: null, userid: userId }
    setselectedChat(dispatch, data);
      
 } 
  
  return (
    <>
      <h5 className="mb-3 px-4 mt-4 font-size-11 text-muted text-uppercase">
       { youtube === true? 'Youtube Viewers List' : 'Youtuber List' }
      </h5>

      <div className="chat-message-list">
        <ul className="list-unstyled chat-list chat-user-list">
          {(users || []).map((user, key) => (
            <span key={key} onClick={() => handleClick(user._id)}>
            <YoutubeChatuser
              user={user}
              key={key}
              youtube={youtube}
              selectedChat={selectedChat}
              onSelectChat={onSelectChat}
            />
            </span>
          ))}
        </ul>
      </div>
    </>
  );
};

export default YoutubeChannel;
