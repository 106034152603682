import React from "react";
import { createSlice } from "@reduxjs/toolkit";
const demographicSlice = createSlice({
  name: "demographic",
  initialState: {
    profile: null,
    isFetching: false,
    error: false,
  },
  reducers: {
    demographicLoding: (state) => { 
      state.isFetching = true;
    },
    demographicSuccess: (state, action) => {
      state.isFetching = false;
      state.error = false;
    },
    demographicFailure: (state, action) => {
      state.isFetching = false;
      state.error = true;
      console.log('error data :- ', action)
    },
  },
});

export const { demographicLoding, demographicSuccess, demographicFailure } =
  demographicSlice.actions;
export const demographicReducer = demographicSlice.reducer;

const detaildprofileSlice = createSlice({
  name: "detaildprofile",
  initialState: {
    profile: null,
    isFetching: false,
    error: false,
    sucess:false,
  },
  reducers: {
    detaildprofileLoding: (state) => { 
      state.isFetching = true;
      state.sucess=false;
    },
    detaildprofileSuccess: (state, action) => {
      state.isFetching = false;
      state.error = false;
      state.sucess=true;
      window.scrollTo(0, 0);
    },
    detaildprofileFailure: (state, action) => {
      state.sucess=false;
      state.isFetching = false;
      state.error = true;
      console.log('error data :- ', action)
    },
    resetMessage:(state)=>{
      state.sucess=false;
    },
  },
});

export const { detaildprofileLoding, detaildprofileSuccess, detaildprofileFailure, resetMessage} =
detaildprofileSlice.actions;
export const detaildprofileReducer = detaildprofileSlice.reducer;

const notificationSlice = createSlice({
  name: "notification",
  initialState: {
    profile: null,
    error: false,
    sucess:false,
  },
  reducers: {
    notificationSuccess: (state, action) => {
      state.error = false;
      state.sucess = true;
      window.scrollTo(0, 0);
    },
    notificationFailure: (state, action) => {
      state.error = true;
      state.sucess = false;
      console.log('post data :- ', action)
    },
    resetNotification:(state)=>{
      state.sucess=false;
    },
  },
});

export const { notificationSuccess, notificationFailure, resetNotification} =
notificationSlice.actions;
export const notificationReducer = notificationSlice.reducer;
