import React from "react";
import {
  loginFailure,
  loginStart,
  loginSuccess,
  loading,
  verifyemail,
  incorrectemail,
  changeLayoutmode,
  selectedChat,
  selectedGroupChat,
  selectedUserMessage,
  chatUserDetails,
  conversations,
  layout,
  updateCurrentUser, 
} from "./userRedux";
import {
  notificationSuccess,
  notificationFailure,
  demographicLoding,
  demographicSuccess,
  demographicFailure,
  detaildprofileLoding,
  detaildprofileSuccess,
  detaildprofileFailure,
} from "./clientRedux";
import { publicRequest, userRequest } from "../requestMethods";
import axios from "axios";

// export const changeTab = (layoutMode) => ({
//   type: 'dark',
//   payload: layoutMode,
// });

export const changelayoutMode = async (data ) => {
  //dispatch(changeLayoutmode());  
};

export const setcurrentUser = async (dispatch,data ) => {
  try {   
    dispatch(updateCurrentUser(data));  
  } catch (err) {
    console.log("Error ", err);    
  }  
};

export const deleteGroupmember = async (dispatch,data ) => {
  try {
    const res = await publicRequest.post("/group/channel", data);
    return res;   
  } catch (err) {
    console.log("Error ", err);   
  }
};

export const deleteUsermessage = async (dispatch,data ) => { 
  try {
    //const res = await publicRequest.delete("/messages/user/"+ data.mid);
    dispatch(selectedUserMessage(data.mid));
  } catch (err) {
    console.log("Error ", err);   
  }
};

export const changeSelectedTab = async (dispatch,data ) => {
  try {
    dispatch(layout(data));
  } catch (err) {
    console.log("Error ", err);    
  }  
};

export const setselectedChat = async (dispatch,data ) => {  
  if(data.groupid !== null) {
    dispatch(selectedChat(data.groupid));
    dispatch(selectedGroupChat(data.groupid));  
  } else{
    dispatch(selectedGroupChat(null));
    dispatch(selectedChat(data.userid)); 
  }
   
};

export const changeSelectedChat = async (dispatch,data ) => {
  dispatch(selectedGroupChat(null));
  dispatch(selectedChat(null));
  dispatch(selectedUserMessage(null));  
};

export const changeChatUserDetails = async (dispatch,data ) => { 
  dispatch(chatUserDetails(null));  
};

export const setchatUserDetails = async (dispatch,data ) => {
  try {   
    dispatch(chatUserDetails(data));  
  } catch (err) {
    console.log("Error ", err);    
  }  
};

export const setConversations = async (dispatch,data ) => {
  try {   
    dispatch(conversations(data));  
  } catch (err) {
    console.log("Error ", err);    
  }  
};

export const signup = async (data) => { 
  try {
    const res = await userRequest.post("/auth/register", data);   
  } catch (err) {
    console.log("Error ", err);   
  }
};

export const signin = async (dispatch, user) => {
  dispatch(loginStart());
  try {
    const res = await publicRequest.post("/auth/login", user);
    dispatch(loginSuccess(res.data));
  } catch (err) {
    console.log("Error ", err);
    dispatch(loginFailure(err));
  }
};

export const createGroup = async (dispatch,data) => { 
  try {
    const res = await publicRequest.post("/group/create", data);
    return res;   
  } catch (err) {
    console.log("Error ", err);   
  }
};

export const resetpassword = async (dispatch, user) => {
  dispatch(loading());
  try {
    const res = await publicRequest.post("/auth/forgot", user);
    //(verifyemail(res.data));
  } catch (err) {
    console.log("Error ", err);
    //(incorrectemail(err));
  }
};

export const demographic = async (dispatch, data) => {
  dispatch(demographicLoding());
  try {
    const res = await userRequest.post("/user/profile/demographic", data);
    dispatch(demographicSuccess(res.data));
  } catch (err) {
    console.log("Error ", err);
    dispatch(demographicFailure(err));
  }
};

export const detailldprofile = async (dispatch, data) => {
  dispatch(detaildprofileLoding());
  try {
    const res = await userRequest.post("/user/profile/detailed_profile", data);
    dispatch(detaildprofileSuccess(res.data));
  } catch (err) {
    console.log("Error ", err);
    dispatch(detaildprofileFailure(err));
  }
};

export const sendnotification = async (dispatch, data) => {
  try {
    const res = await userRequest.post("/user/profile/notification", data);
    dispatch(notificationSuccess(res.data));
  } catch (err) {
    console.log("Error ", err);
    dispatch(notificationFailure(err));
  }
};

