import { APIClient } from "./apiCore";
import * as url from "./urls";

const api = new APIClient();

const getContacts = (filters) => {
  return api.get(url.GET_CONTACTS, filters);
};

const inviteContact = (data) => {
  return api.create(url.INVITE_CONTACT, data);
};
export { getContacts, inviteContact };
