import React from "react";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// actions
import { changelayoutMode } from "../../redux/actions";

// components
import SideMenu from "./SideMenu";
import { useDispatch } from "react-redux";
import Leftbar from "../../pages/Dashboard/Leftbar";

// interface IndexProps {
//   children: any;
// }

const Index = (props) => {
  const dispatch = useDispatch();

  /*
  call dark/light mode
  */
  const onChangeLayoutMode = (value) => {
    if (changelayoutMode) {
      dispatch(changelayoutMode(value));
    }
  }; 

  return (
    <div className="layout-wrapper d-lg-flex">
      <SideMenu />     
      {/* {props.children} */}
      <ToastContainer autoClose={2000} />
    </div>
  );
};

export default Index;
