import React, { useState,useEffect } from "react";

import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

// interface
import { BasicDetailsTypes } from "../../../data/myProfile";
import { useDispatch, useSelector } from "react-redux";
import banner from "../../../assets/images/users/avatar-1.jpg";
import AppSimpleBar from "../../../components/AppSimpleBar";

import {
  showSuccessNotification,
  showErrorNotification,
} from "../../../helpers/notifications";

import { setcurrentUser } from "../../../redux/apiCalls";

import axios from 'axios';

const MyProfile = ({basicDetails}) => {

  const dispatch = useDispatch();

  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggle = () => setDropdownOpen(!dropdownOpen);

  const {currentUser,layout } = useSelector((state) => state.user);

  //const [ basicDetails , setBasicdetails ] = useState(null);

  const [post, setPost] = useState(null);

  const [ disable, setDisable] = useState(false);

  const FriendfollowingsUserStatus = currentUser.followings.includes(basicDetails._id);
  console.log('FriendfollowingsUserStatus',FriendfollowingsUserStatus)

  const FriendfollowersUserStatus = currentUser.followers.includes(basicDetails._id);
  console.log('FriendfollowersUserStatus',FriendfollowersUserStatus)

  const CurrentUserStatus = currentUser.frequest.includes(basicDetails._id);
  console.log('currentUserDetails',currentUser)
  console.log('Currentstatus',CurrentUserStatus)

const BasicUserStatus = basicDetails?.frequest.includes(currentUser._id);
console.log('basicDetails',basicDetails)
  console.log('BasicUserStatus',BasicUserStatus)

  const [ friendrequest , setFriendrequest ] = useState(true);

  const [ friendrequestdisabled, setFriendrequestdisabled ] = useState(false);

  // get Current User Live Details 
  const getCurrentUser = async () => {
    try { 
    const selectedChat = currentUser._id;
                 
      const res = await axios.get("/users/friend/" + selectedChat);
      //setCurrentuser(res);
      //setFollowed(CurrentUserStatus)
      setcurrentUser(dispatch,res);
    
  } catch (err) {
    console.log(err);
  }
} 

    const getPostCount = async () => {
      try { 
      const userId = layout.chatUserId !== null ? layout?.chatUserId : currentUser?._id;
      if(( userId !== undefined ))
      {              
        const res = await axios.get("/post/count/" + userId);
        setPost(res);
        // (layout.chatUserId !== null && layout.chatUserId !== undefined) ? setPost(res) : setPost(res.data); 
      } 
    } catch (err) {
      console.log(err);
    }
  } 

    useEffect(() => {
      if(layout.chatUserId !==null){
        getCurrentUser();
        setPost(null);
       // getFriendProfile();
        getPostCount();
      }else if(layout.chatUserId === null){  
        setPost(null);
       // getCurrentProfile();
        getPostCount();
      }
    },[layout.chatUserId])
 
 
    const fullName = basicDetails
    ? `${basicDetails.firstname} ${basicDetails.lastname}`
    : "-";

  //const followerDifferences = currentUser?.followings.includes(basicDetails._id);

  const handleClick = async (uId) => {
         const fId = currentUser._id;
      const res = await axios.put("/post/friendrequest/" + uId + '/' + fId);
      setDisable(true);
      setFriendrequest(false);
      showSuccessNotification('Request Send Successfully...');
  }  

  const handleRejectRequest = async (uId) => {   
    try {      
        await axios.put(`/users/${uId}/unfollow`, {
          userId: currentUser._id,
        });
        setDisable(true);
        showSuccessNotification('Reject Request Send Successfully...');
      } catch (err) {
        console.log(err)
    }
  }
  
  //Create First Conversations Code Start
  const insertConversessionUser = async (currentId,user) => {
    console.log('insert',currentId)
    console.log('insertUser',user)   
   const res = await axios.post(
     `/conversations/${currentId}/${user}`
   );
 }
  //First Conversations Code End
      
  const handleAcceptRequest = async (uId) => {   
    try {    
        await axios.put(`/users/${uId}/follow`, {
          userId: currentUser._id,
        });
        insertConversessionUser(uId,currentUser._id)
        setDisable(true);
        showSuccessNotification('Accept Request Send Successfully...');     
    } catch (err) {
        console.log(err)
    }
  };

   
      const SendFriendRequest = () => {
        if((FriendfollowingsUserStatus === true) || (FriendfollowersUserStatus === true)) {     
          return (
            <>
              <button type="button" disabled={disable} style={{float:'left'}} className="btn btn-primary mb-lg btn btn-secondary">
              Following </button>

              <button onClick={() => handleRejectRequest(basicDetails._id)} type="button" disabled={disable} style={{float:'right'}} className="btn btn-primary mb-lg btn btn-secondary">
              Unfollow </button>
            </>
          );
      }

       else if((CurrentUserStatus === true)) {     
          return (
            <>
              <button onClick={() => handleAcceptRequest(basicDetails._id)} type="button" disabled={disable} style={{float:'left'}} className="btn btn-primary mb-lg btn btn-secondary">
              Accept </button>

              <button onClick={() => handleRejectRequest(basicDetails._id)} type="button" disabled={disable} style={{float:'right'}} className="btn btn-primary mb-lg btn btn-secondary">
              Reject </button>
            </>
          );
      }
       else if((BasicUserStatus === false)) {     
          return (
            <>
              { 
                (currentUser._id === basicDetails._id) ?
                  ('') : (<button onClick={() => handleClick(basicDetails._id)} type="button" disabled={disable} style={{float:'left'}} className="btn btn-primary mb-lg btn btn-secondary">
                Send Friend Request </button> ) 
              }
            </>
          );
      }
       else if((BasicUserStatus === true)) {     
            return (
              <>
                <button type="button" disabled style={{float:'left'}} className="btn btn-primary mb-lg btn btn-secondary">
                      Send Friend Request </button>
              </>
            );
        }        
      };
 
  return (
    <>
      <div className="user-profile-img">
        {basicDetails && (
          <img
            src='http://localhost:3000/static/media/img-4.c7a84ad5.jpg'
            className="profile-img"
            style={{ height: "160px" }}
            alt=""
          />
        )}

        <div className="overlay-content">
          <div>
            <div className="user-chat-nav p-2 ps-3">
              <div className="d-flex w-100 align-items-center">
                <div className="flex-grow-1">
                  <h5 className="text-white mb-0"> {basicDetails? `${fullName}` +' '+ 'Profile' : 'My Profile'}</h5>
                </div>
                <div className="flex-shrink-0">
                  <Dropdown isOpen={dropdownOpen} toggle={toggle}>
                    <DropdownToggle
                      color="none"
                      className="btn nav-btn text-white"
                      type="button"
                    >
                      <i className="bx bx-dots-vertical-rounded"></i>
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-menu-end">
                      <DropdownItem
                        className="d-flex align-items-center justify-content-between"
                        href="#"
                      >
                        Info{" "}
                        <i className="bx bx-info-circle ms-2 text-muted"></i>
                      </DropdownItem>
                      <DropdownItem
                        className="d-flex align-items-center justify-content-between"
                        href="#"
                      >
                        Setting <i className="bx bx-cog text-muted ms-2"></i>
                      </DropdownItem>
                      <DropdownItem divider />
                      <DropdownItem
                        className="d-flex align-items-center justify-content-between"
                        href="#"
                      >
                        Help{" "}
                        <i className="bx bx-help-circle ms-2 text-muted"></i>
                      </DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="text-center p-3 p-lg-4 border-bottom pt-2 pt-lg-2 mt-n5 position-relative">
        <div className="mb-lg-3 mb-2">
          {basicDetails && (
            <img
              src={banner}
              className="rounded-circle avatar-lg img-thumbnail"
              alt=""
            />
          )}
        </div>

        <h5 className="font-size-16 mb-1 text-truncate">{fullName}</h5>        
        <p className="text-muted font-size-14 text-truncate mb-0">
          {basicDetails && basicDetails.desc ? basicDetails.desc : "-"}
        </p>
        
        <br /> 
        <div className="mb-lg-3 mb-2">

                  
            <SendFriendRequest /> 
           
                
         

         

          {/* { ((basicDetails._id !== currentUser._id) && (currentUser.frequest.includes(basicDetails._id) === false)) ?
            <SendFriendRequest /> ? <SendFriendRequestDisabled /> : <SendFriendRequest /> :  <SendFriendRequest />  ?  <AcceptFriendRequest /> : ''
          } */}

          {/* { ((basicDetails._id !== currentUser._id) && (currentUser.followers.includes(basicDetails._id) === true)) ?
            <SendFriendRequestDisabled /> : '' 
          } */}

          {/* { ((basicDetails._id !== currentUser._id) && (basicDetails.frequest.includes(currentUser._id) === true)) ?
          <SendFriendRequestDisabled /> : ''
          } */}
          
          {/* {
              ((basicDetails._id !== currentUser._id) && (followerDifferences === false)) ? (
                currentUser.frequest.includes(basicDetails._id) === false ?
                (<button onClick={() => handleClick(basicDetails._id)} type="button" disabled={disable} style={{float:'left'}} className="btn btn-primary mb-lg btn btn-secondary">
              Send Friend Request </button>) : ''): ''
           } */}
        {/* {
          ((followerDifferences === false)) ? (
            currentUser.frequest.includes(basicDetails._id) === true ?
            (<button onClick={() => handleClick(basicDetails._id)} type="button" disabled={disable} style={{float:'left'}} className="btn btn-primary mb-lg btn btn-secondary">
          Accept </button>) :
            currentUser.frequest.includes(basicDetails._id) === false ?
        (<button onClick={() => handleClick(basicDetails._id)} type="button" disabled={disable} className="btn btn-primary w-lg btn btn-secondary">
          Send Friend Requests</button> ) : <button type="button" disabled className="btn btn-primary w-lg btn btn-secondary">
          Send Friend Request</button> )
          : (followerDifferences ? ( <button type="button" className="btn btn-primary w-lg btn btn-secondary">
          user Following </button> ) : '')
          }
          <hr className="my-4" style={{ display: 'none' }}/>
          {
          ((basicDetails._id !== currentUser._id) && (followerDifferences === false)) ? (
            currentUser.frequest.includes(basicDetails._id) === true ?
            (<button onClick={() => handleClick(basicDetails._id)} type="button" disabled={disable} className="btn btn-primary mb-lg btn btn-secondary" style={{float: 'right' }}>
         Reject </button>) : '' ) : '' } */}
         </div>
      </div>

      {/* UserDescription Code Start */}

      <AppSimpleBar className="p-4 profile-desc">
      <div className="text-muted">
        <p className="mb-4">
          {basicDetails && basicDetails.desc
            ? basicDetails.desc
            : "-"}
        </p>
      </div>

      <div>
        <div className="d-flex py-2">
          <div className="flex-shrink-0 me-3">
            <i className="bx bxs-user-detail align-middle text-muted"></i>
          </div>
          <div className="flex-grow-1" style={{display:'flex'}}>
            <p className="mb-0">
              {`Followers`}              
            </p>
            <div className="ms-auto">
              <span className="badge badge-soft-dark rounded p-1">{basicDetails.followers.length}</span>
            </div>
          </div>
        </div>

        <div className="d-flex py-2">
          <div className="flex-shrink-0 me-3">
            <i className="bx bxs-user-detail align-middle text-muted"></i>
          </div>
          <div className="flex-grow-1" style={{display:'flex'}}> 
            <p className="mb-0">
              {`Following`}
            </p>
            <div className="ms-auto">
              <span className="badge badge-soft-dark rounded p-1">{basicDetails.followings.length}</span>
            </div>
          </div>
        </div>

        <div className="d-flex py-2">
          <div className="flex-shrink-0 me-3">
            <i className="bx bx-bookmarks align-middle text-muted"></i>
          </div>
          <div className="flex-grow-1" style={{display:'flex'}}>
            <p className="mb-0">
              {`Post`}              
            </p>
            <div className="ms-auto">
              <span className="badge badge-soft-dark rounded p-1">{post}</span>
            </div>
          </div>
        </div>

        <hr className="my-4" />
        <div className="d-flex py-2">
          <div className="flex-shrink-0 me-3">
            <i className="bx bx-location-plus align-middle text-muted"></i>
          </div>
          <div className="flex-grow-1">
            <p className="mb-0">
              {basicDetails && basicDetails.city
                ? basicDetails.city
                : "-"}
            </p>
          </div>
        </div>
      </div>
      </AppSimpleBar>

    </>
  );
};
export default MyProfile;
