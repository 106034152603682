import React, { useState, useEffect } from "react";
import { Button, Collapse } from "reactstrap";
import classnames from "classnames";

// hooks
//import { useRedux } from "../../../hooks/index";
import { useDispatch, useSelector } from "react-redux";

// actions
import { getSettings, updateSettings } from "../../../redux/actions";

// constants
import { SETTINGS_COLLAPSES } from "../../../constants";

// interface
import { SettingsTypes } from "../../../data/settings";

// components
import Loader from "../../../components/Loader";
import AppSimpleBar from "../../../components/AppSimpleBar";
import UserCoverImage from "./UserCoverImage";
import UserProfile from "./UserProfile";
import PersonalInfo from "./PersonalInfo";
import ThemeSettings from "./ThemeSettings";
import Privacy from "./Privacy";
import Security from "./Security";
import Help from "./Help";

// interface CollapseItemTypes {
//   value:
//     | SETTINGS_COLLAPSES.PROFILE
//     | SETTINGS_COLLAPSES.HELP
//     | SETTINGS_COLLAPSES.PRIVACY
//     | SETTINGS_COLLAPSES.SECURITY
//     | SETTINGS_COLLAPSES.THEME;
//   label: string;
//   icon: string;
//   component: any;
// }

// interface AccordianItemProps {
//   item: CollapseItemTypes;
//   onChange: (
//     id:
//       | null
//       | SETTINGS_COLLAPSES.PROFILE
//       | SETTINGS_COLLAPSES.HELP
//       | SETTINGS_COLLAPSES.PRIVACY
//       | SETTINGS_COLLAPSES.SECURITY
//       | SETTINGS_COLLAPSES.THEME
//   ) => void;
//   selectedMenu:
//     | null
//     | SETTINGS_COLLAPSES.PROFILE
//     | SETTINGS_COLLAPSES.HELP
//     | SETTINGS_COLLAPSES.PRIVACY
//     | SETTINGS_COLLAPSES.SECURITY
//     | SETTINGS_COLLAPSES.THEME;
// }
const AccordianItem = ({
  item,
  selectedMenu,
  onChange,
}) => {
  const isOpen =
    selectedMenu && selectedMenu === item.value ? true : false;
  const toggleCollapse = () => {
    if (isOpen) {
      onChange(null);
    } else {
      onChange(item.value);
    }
  };
  return (
    <div className="accordion-item">
      <div className="accordion-header" id="headerpersonalinfo">
        <Button
          color="none"
          className={classnames(
            "accordion-button",
            "font-size-14",
            "fw-medium",
            { collapsed: !isOpen }
          )}
          onClick={toggleCollapse}
          type="button"
        >
          <i className={classnames("text-muted", "me-3", item.icon)}></i>{" "}
          {item.label}
        </Button>
      </div>
      <Collapse
        isOpen={isOpen}
        id="personalinfo"
        className="accordion-collapse"
        aria-labelledby="headerpersonalinfo"
        data-bs-parent="#settingprofile"
      >
        {item.component}
      </Collapse>
    </div>
  );
};

// interface IndexProps {}

const Index = (props) => {

  // global store
 // const { dispatch, useAppSelector } = useRedux();

  // const { settingsData, getSettingsLoading } = useAppSelector(state => ({
  //   settingsData: state.Settings.settings,
  //   getSettingsLoading: state.Profile.getSettingsLoading,
  //   isSettingsFetched: state.Profile.isSettingsFetched,
  // }));

  const dispatch = useDispatch();

  const { getSettingsLoading,currentUser } = useSelector((state) => state.user);

  // get user settings
  // useEffect(() => {
  //   dispatch(getSettings());
  // }, [dispatch]);

  const settingsData = [
    {settings: [
      { basicDetails: 
        {
          firstName: 'amit',
          lastName: 'sadaphal',
        }
      },
      { theme: 
        {
          image: 'bgimg-radio5',          
        },
      },
      { privacy: 
        {
          displayprofilePhoto: 'selected',          
        },
      },
      { security: 
        {
          securityNotification: false,          
        },
      }
     ]      
     }
  ];

  console.log("settingsData",settingsData)

  const [settings, setSettings] = useState(settingsData);

  useEffect(() => {
    setSettings(settingsData);
  }, []);

  /*
  api calling
  */
  const onChangeData = (field, value) => {
    dispatch(updateSettings(field, value));
  };

  /*
  collapse handeling
  */
  const [selectedMenu, setSelectedMenu] = useState(null);

  const collapseItems = [
    {
      value: SETTINGS_COLLAPSES.PROFILE,
      label: "Personal Info",
      icon: "bx bxs-user",
      component: <PersonalInfo basicDetails={settings.basicDetails} />,
    },
    {
      value: SETTINGS_COLLAPSES.THEME,
      label: "Themes",
      icon: "bx bxs-adjust-alt",
      component: (
        <ThemeSettings theme={settings.theme} onChangeData={onChangeData} />
      ),
    },
    {
      value: SETTINGS_COLLAPSES.PRIVACY,
      label: "Privacy",
      icon: "bx bxs-lock",
      component: (
        <Privacy privacy={settings.privacy} onChangeSettings={onChangeData} />
      ),
    },
    {
      value: SETTINGS_COLLAPSES.SECURITY,
      label: "Security",
      icon: "bx bxs-check-shield",
      component: (
        <Security
          security={settings.security}
          onChangeSettings={onChangeData}
        />
      ),
    },
    {
      value: SETTINGS_COLLAPSES.HELP,
      label: "Help",
      icon: "bx bxs-help-circle",
      component: <Help />,
    },
  ];

  const onChangeCollapse = (
    id
  ) => {
    setSelectedMenu(id);
  };

  return (
    <div className="position-relative">
      {getSettingsLoading && <Loader />}
      <UserCoverImage basicDetails={currentUser} />

      <UserProfile
        basicDetails={currentUser}
        status={currentUser.status}
      />
      {/* Start User profile description */}
      <AppSimpleBar className="user-setting">
        <div id="settingprofile" className="accordion accordion-flush">
          {(collapseItems).map((item, key) => (
            <AccordianItem
              item={item}
              key={key}
              selectedMenu={selectedMenu}
              onChange={onChangeCollapse}
            />
          ))}
        </div>
        {/* end profile-setting-accordion */}
      </AppSimpleBar>
    </div>
  );
};

export default Index;
