import React, { useEffect } from "react";

// scss
import "./assets/scss/theme.scss";

// actions
import { useSelector, useDispatch } from "react-redux";
//import {changelayoutMode} from "./redux/apiCalls";
//import { changelayoutMode } from "./redux/actions";

//Route
import Routes from "./routes";

// Import Firebase Configuration file
// import { initFirebaseBackend } from "./helpers/firebase_helper";

//api config
// import config from "./config";
//import fakeBackend from "./helpers/fakeBackend";


// TODO
//fakeBackend();

// const firebaseConfig = {
//   apiKey: config.FIRE_BASE.API_KEY,
//   authDomain: config.FIRE_BASE.AUTH_DOMAIN,
//   databaseURL: config.FIRE_BASE.DATABASEURL,
//   projectId: config.FIRE_BASE.PROJECTID,
//   storageBucket: config.FIRE_BASE.STORAGEBUCKET,
//   messagingSenderId: config.FIRE_BASE.MESSAGINGSENDERID,
//   appId: config.FIRE_BASE.APPID,
//   measurementId: config.FIRE_BASE.MEASUREMENTID,
// };

// // init firebase backend
// initFirebaseBackend(firebaseConfig);

const App = () => {
  const dispatch = useDispatch();

  // const { layoutMode } = useSelector((state) => ({
  //   layoutMode: state.Layout.layoutMode,
  // }));
  const { layoutMode } = useSelector((state) => state.user);

  // Dark/Light Mode 
  useEffect(() => {
    // var getLayoutMode = layoutMode;
    // if (getLayoutMode) {      
    //   dispatch(changelayoutMode(getLayoutMode));
    // } else {      
    //   dispatch(changelayoutMode(layoutMode));
    // }
  }, [layoutMode, dispatch]);

  return <Routes />;
};

export default App;
