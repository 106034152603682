export const LAYOUT_MODES = {
  LIGHT : "light",
  DARK : "dark",
}

export const CHATS_TABS = {
  ARCHIVE : "acrhive",
  DEFAULT : "default",
}

export const TABS = {
  USERS : "pills-user",
  CHAT : "pills-chat",
  CONTACTS : "pills-contacts",
  CALLS : "pills-calls",
  BOOKMARK : "pills-bookmark",
  SETTINGS : "pills-setting",
}