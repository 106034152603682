//redux
import { TypedUseSelectorHook, useSelector, useDispatch } from "react-redux";

//import type { RootState, AppDispatch } from "../redux/store";

const useRedux = () => {
  const dispatch = useDispatch();
  const useAppSelector = useSelector;
  return { dispatch, useAppSelector };
};

export { useRedux };
