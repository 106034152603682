import React, { useEffect, useState } from "react";

import { Link, Redirect  } from "react-router-dom";
import {
  Nav,
  NavItem,
  Dropdown,
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
  NavLink,
  UncontrolledTooltip,
} from "reactstrap";

// hooks
//import { useRedux } from "../../hooks/index";
import { useDispatch, useSelector } from "react-redux";

// actions
import { changeTab } from "../../redux/actions";

// costants
import { TABS } from "../../constants/index";
import LightDarkMode from "../../components/LightDarkMode";
import { changeSelectedTab } from "../../redux/apiCalls";

//images
import avatar1 from "../../assets/images/users/avatar-1.jpg";

// menu
import { MENU_ITEMS, MenuItemType } from "./menu";
import Leftbar from "../../pages/Dashboard/Leftbar";
import { logOut } from "../../redux/userRedux";

const LogoLightSVG = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 24 24"
    >
      <path d="M8.5,18l3.5,4l3.5-4H19c1.103,0,2-0.897,2-2V4c0-1.103-0.897-2-2-2H5C3.897,2,3,2.897,3,4v12c0,1.103,0.897,2,2,2H8.5z M7,7h10v2H7V7z M7,11h7v2H7V11z" />
    </svg>
  );
};

const LogoDarkSVG = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 24 24"
    >
      <path d="M8.5,18l3.5,4l3.5-4H19c1.103,0,2-0.897,2-2V4c0-1.103-0.897-2-2-2H5C3.897,2,3,2.897,3,4v12c0,1.103,0.897,2,2,2H8.5z M7,7h10v2H7V7z M7,11h7v2H7V11z" />
    </svg>
  );
};

const Logo = () => {
  return (
    <div className="navbar-brand-box">
      <NavLink href="/dashboard" className="logo logo-dark">
        <span className="logo-sm">
          <LogoLightSVG />
        </span>
      </NavLink>

      <NavLink href="/dashboard" className="logo logo-light">
        <span className="logo-sm">
          <LogoDarkSVG />
        </span>
      </NavLink>
    </div>
  );
};

// interface MenuNavItemProps {
//   item: MenuItemType;
//   selectedTab:
//     | TABS.BOOKMARK
//     | TABS.CALLS
//     | TABS.CHAT
//     | TABS.CONTACTS
//     | TABS.SETTINGS
//     | TABS.USERS;
//   onChangeTab: (
//     id:
//       | TABS.BOOKMARK
//       | TABS.CALLS
//       | TABS.CHAT
//       | TABS.CONTACTS
//       | TABS.SETTINGS
//       | TABS.USERS
//   ) => void;
// }

const MenuNavItem = ({ item, selectedTab, onChangeTab }) => {
  const onClick = () => {
    onChangeTab(item.tabId);
  }; 

  
  console.log('selectedTab',selectedTab)
  console.log('itemTabId',item.tabId)
  console.log('item',item)
  console.log('onChangeTab',onChangeTab)

  return (
    <>
      <NavItem className={item.className} id={`${item.key}-container`}>
        <NavLink
          href="#"
          active={selectedTab === item.tabId}
          id={item.key}
          role="tab"
          onClick={onClick}
        >
          <i className={item.icon}></i>
        </NavLink>
      </NavItem>
      <UncontrolledTooltip target={`${item.key}-container`} placement="right">
        {item.tooltipTitle}
      </UncontrolledTooltip>
    </>
  );
};

// interface ProfileDropdownMenuProps {
//   onChangeTab: (
//     id:
//       | TABS.BOOKMARK
//       | TABS.CALLS
//       | TABS.CHAT
//       | TABS.CONTACTS
//       | TABS.SETTINGS
//       | TABS.USERS
//   ) => void;
// }

const ProfileDropdownMenu = ({ onChangeTab }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen(!dropdownOpen);
  const dispatch = useDispatch();

  const logoutClick = () => {
    dispatch(logOut());
  }

  return (
    <Dropdown
      nav
      isOpen={dropdownOpen}
      className="profile-user-dropdown"
      toggle={toggle}
    >
      <DropdownToggle nav className="bg-transparent">
        <img src={avatar1} alt="" className="profile-user rounded-circle" />
      </DropdownToggle>
      <DropdownMenu>
        <DropdownItem
          className="d-flex align-items-center justify-content-between"
          onClick={() => onChangeTab(TABS.USERS)}
        >
          Profile <i className="bx bx-user-circle text-muted ms-1"></i>
        </DropdownItem>
        <DropdownItem
          className="d-flex align-items-center justify-content-between"
          onClick={() => onChangeTab(TABS.SETTINGS)}
        >
          Setting <i className="bx bx-cog text-muted ms-1"></i>
        </DropdownItem>
        <DropdownItem
          className="d-flex align-items-center justify-content-between"
          href="/auth-changepassword"
        >
          Change Password <i className="bx bx-lock-open text-muted ms-1"></i>
        </DropdownItem>

        <DropdownItem />       
        <DropdownItem
          className="d-flex align-items-center justify-content-between"
          tag="a"          
          onClick={logoutClick}          
        >
          Log out <i className="bx bx-log-out-circle text-muted ms-1"></i>
        </DropdownItem>      
      </DropdownMenu>
    </Dropdown>
  );
};

const SideMenu = ({ onChangeLayoutMode }) => {
  // global store

  const dispatch = useDispatch();

  const menuItems = MENU_ITEMS;

  const { layout } = useSelector((state) => state.user);

  /* 
    tab activation
    */
  const [selectedTab, setSelectedTab] = useState(TABS.CHAT);

  console.log('TABS.CHAT',TABS.CHAT)

  const onChangeTab = (
    id
  ) => {
    setSelectedTab(id);
    const chatUserId = null;
    const activeTab = id;
    const layoutMode = layout.layoutMode;
   const data = {chatUserId, activeTab , layoutMode }   
    changeSelectedTab(dispatch,data);
   //dispatch(changeTab(id));
  };

  

  useEffect(() => {
    setSelectedTab(layout.activeTab);
  }, [layout.activeTab]);

 


  return (
    <div className="side-menu flex-lg-column">
      {/* LOGO */}
      <Logo />
      {/* end navbar-brand-box */}

      {/* Start side-menu nav */}
      <div className="flex-lg-column my-0 sidemenu-navigation">
        <Nav pills className="side-menu-nav" role="tablist">
          {(menuItems).map((item, key) => (
            <MenuNavItem
              item={item}
              key={key}
              selectedTab={selectedTab}
              onChangeTab={onChangeTab}
            />
          ))}

          {/* change mode */}
          <LightDarkMode
            layoutMode={layout.layoutMode}           
            onChangeLayoutMode={onChangeLayoutMode}
          />

          {/* profile menu dropdown */}
          <ProfileDropdownMenu onChangeTab={onChangeTab} />
        </Nav>
      </div>      
      {/* end side-menu nav */}
    </div>
  );
};

export default SideMenu;
