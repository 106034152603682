import React, { useEffect, useState, useRef, useCallback } from "react";
import { Redirect, useHistory } from "react-router-dom";
import classnames from "classnames";
import styled from 'styled-components';

// hooks
//import { useRedux } from "../../hooks/index";
import { useDispatch, useSelector } from "react-redux";
import { changeSelectedChat,changeChatUserDetails,changeSelectedTab } from "../../../redux/apiCalls";
import { changelayoutMode } from "../../../redux/actions";

// hooks
import { useConversationUserType } from "../../../hooks/index";

// component
import Leftbar from "../Leftbar";
import ConversationUser from "../ConversationUser/index";
import UserProfileDetails from "../UserProfileDetails/index";
import Welcome from "../ConversationUser/Welcome";
import SideMenu from "../../../layouts/Default/index";
import { TABS } from "../../../constants/index";

import axios from "axios";
import { updateCurrentUser,ssostatus,loginSuccess,communicate,communicateuserid} from "../../../redux/userRedux";

const MobileView = styled.div`
  display: flex; 
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;


// interface IndexProps {}
const Index = (props) => {
  // global store
 // const { useAppSelector } = useRedux();

  // const { selectedChat } = useAppSelector(state => ({
  //   selectedChat: state.Chats.selectedChat,
  // }));
 
  const history = useHistory();

  const dispatch = useDispatch();

  const { selectedChat,isUserLogin,layout,token,currentUser,isLoggedIn } = useSelector((state) => state.user);

  const { isChannel } = useConversationUserType();

  // if(ref.current === false){
  //   const urlParams = new URLSearchParams(window.location.search);
  //   const data = {};      
  //   for (let [key, value] of urlParams.entries()) {
  //     if (key === 'key') {
  //       key = '_id'; // Replace the key with '_id'
  //     }  
  //     if (key !== 'email' && key !== 'password') {
  //       // Remove the 'key=' prefix from the value
  //       const cleanValue = value.substring(value.indexOf('=') + 1);
  //       data[key] = cleanValue;
  //     }
  //   }
  //   if(data.youtube === false){
  //     dispatch(updateCurrentUser(data));
  //     dispatch(ssostatus(true));        
  //     console.log('currentUser',data);      
  //   }
  // //const redirectUrl = 'http://localhost:3001/dashboard';
  // //window.location.assign(redirectUrl);
  // //ref.current = true;
  // }

  

  useEffect(() => {
    const activeTab = 'pills-chat';
    const layoutMode = layout.layoutMode;
   const data = { activeTab , layoutMode }   
    changeSelectedTab(dispatch,data);
    console.log('Dashboard');
  },[])

  // useEffect(() => {
  //   if (redirected === false) {
  //     const url = 'http://localhost:3001/dashboard'; // Replace with the desired redirect URL
  //     window.location.href = url;
  //     setRedirected(true);
  //   }
  // }, []);
  

  /*
  call dark/light mode
  */
  const onChangeLayoutMode = (value) => {
    if (changelayoutMode) {
      dispatch(changelayoutMode(value));
    }
  };
  
  useEffect(() => {
    changeSelectedChat(dispatch,null);
    changeChatUserDetails(dispatch,null);
  },[])

  // const urlParams = new URLSearchParams(window.location.search);
  // const data = {};      
  // for (let [key, value] of urlParams.entries()) {
  //   if (key === 'key') {
  //     key = '_id'; // Replace the key with '_id'
  //   }  
  //   if (key !== 'email' && key !== 'password') {
  //     // Remove the 'key=' prefix from the value
  //     const cleanValue = value.substring(value.indexOf('=') + 1);
  //     data[key] = cleanValue;
  //   }
  // }
  
 // useEffect(() => {
  const singleSignon = async () => {
      const urlParams = new URLSearchParams(window.location.search);

      if (urlParams.has('key') === true) {
        console.log('urlparamsget', urlParams.get('key'));
      }
      const record = {};      
      for (let [key, value] of urlParams.entries()) {
        if (key === 'key') {
          key = '_id'; // Replace the key with '_id'
        }  
        if (key !== 'email' && key !== 'password') {
          // Remove the 'key=' prefix from the value
          const cleanValue = value.substring(value.indexOf('=') + 1);
          record[key] = cleanValue;
        }
      }
        console.log('currentUser',record);
        dispatch(updateCurrentUser(record));
        dispatch(ssostatus(true));
        dispatch(loginSuccess(record));
        dispatch(communicateuserid(record.userId));
        dispatch(communicate(true));
        console.log('urlParams',urlParams.has('key'));
        // Redirect to the desired URL
      const redirectUrl = process.env.PUBLIC_URL;
      //history.push(redirectUrl);
      window.location.href = `${redirectUrl}/dashboard`;
  }

  singleSignon();
   

  return (
    <>
    <div style={{display: 'flax'}}>
    <MobileView >
      <div className="layout-wrapper d-lg-flex">
      <SideMenu onChangeLayoutMode={onChangeLayoutMode}/>
      
      </div>
      <div>
      <Leftbar />
      </div>

      <div
        className={classnames("user-chat", "w-100", "overflow-hidden", {
          "user-chat-show": selectedChat,
        })}
        id="user-chat"
      >
        <div className="user-chat-overlay" id="user-chat-overlay"></div>
        {selectedChat !== null ? (
          <div className="chat-content d-lg-flex">
            <div className="w-100 overflow-hidden position-relative">
              <ConversationUser isChannel={isChannel} />
            </div>
            {/* <UserProfileDetails isChannel={isChannel} /> */}
          </div>
        ) : (
          <Welcome />
        )}
      </div>
      </MobileView>
      </div>   
      
    </>
  );
};

export default Index;
