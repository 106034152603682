import React from "react";
import axios from "axios";

const BASE_URL = "http://172.16.1.143/api/";
const GET_ALL_TOKEN ="A?D(G+KbPeShVmYq3t6w9y$B&E)H@McQfTjWnZr4u7x!A%C*F-JaNdRgUkXp2s5v";

export const publicWPRequest = axios.create({
  baseURL: process.env.WP_REST_API_URL,
});

export const publicRequest = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: { "non-login-access-key": `${GET_ALL_TOKEN}` },  
});

export const userRequest = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

// userRequest.interceptors.request.use((request)=>{ 
//   request.headers = {
//     "x-access-token": (localStorage.getItem('persist:root'))? JSON.parse(JSON.parse(localStorage.getItem('persist:root')).user).currentUser.accessToken : null
//   }
//   return request;
// },(error)=> {
//   console.log("Login persist root error  ", error)
//   return Promise.reject(error);
// });

// userRequest.interceptors.response.use((response)=>{ 
//   return response;
// },(error)=> {
//   // if(error.response.data.auth)
//   return Promise.reject(error);
// });
