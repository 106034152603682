import React from "react";
import { UncontrolledTooltip } from "reactstrap";

//components
import AddButton from "../../../components/AddButton";

// interface
import { UserTypes } from "../../../data/chat";

import { useDispatch, useSelector } from "react-redux";

import { setselectedChat,setchatUserDetails,changeSelectedTab } from "../../../redux/apiCalls";

// component
import ChatUser from "./ChatUser";

// interface DirectMessagesProps {
//   users: Array<UserTypes>;
//   openAddContact: () => void;
//   selectedChat: string | number;
//   onSelectChat: (id: number | string) => void;
// }

const DirectMessages = ({
  users,
  openAddContact,
  setSelectedChat,
  selectedChat,
  onSelectChat,
}) => {

  const { layout } = useSelector((state) => state.user);
  
  const dispatch = useDispatch();

  const handleClick = (userId) => {
    console.log("e",userId)
    //setSelectedChat(userId);
    //setselectedChat(dispatch, userId);
    
    const chatUserId = userId;
    const activeTab = 'pills-user';
    const layoutMode = layout.layoutMode;
   const data = {chatUserId, activeTab , layoutMode }   
    changeSelectedTab(dispatch,data);
 }

  return (
    <>
      <div className="d-flex align-items-center px-4 mt-5 mb-2">
        <div className="flex-grow-1">
          <h4 className="mb-0 font-size-11 text-muted text-uppercase">
           Friend Request List
          </h4>
        </div>
        <div className="flex-shrink-0">
          <div id="new-message" title="New Message">
            {/* Button trigger modal */}
            <AddButton onClick={openAddContact} /> {/* contactModal */}
          </div>
          <UncontrolledTooltip target="new-message" placement="bottom">
            New Message
          </UncontrolledTooltip>
        </div>
      </div>

      <div className="chat-message-list">
        <ul className="list-unstyled chat-list chat-user-list">
          {(users || []).map((user, key) => (
            <span key={key} onClick={() => handleClick(user._id)}>
            <ChatUser
              user={user}
              key={key}
              selectedChat={selectedChat}
              onSelectChat={onSelectChat}
            />
            </span>
          ))}
        </ul>
      </div>
    </>
  );
};

export default DirectMessages;
