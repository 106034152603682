import React, { useEffect, useRef, useCallback, useState,useMemo } from "react";

// hooks
import { useRedux } from "../../../hooks/index";

// hooks
import { useProfile } from "../../../hooks";

// components
import AppSimpleBar from "../../../components/AppSimpleBar";
import Loader from "../../../components/Loader";
import Message from "./Message";
// import Day from "./Day";

import ChatInputSection from "./ChatInputSection/index";

// interface
import { MessagesTypes } from "../../../data/messages";
import ForwardModal from "../../../components/ForwardModal";

// actions
import { setConversations,deleteUsermessage } from "../../../redux/apiCalls";
import { forwardMessage, deleteImage } from "../../../redux/actions";
import { useDispatch,useSelector } from "react-redux";
import {io} from "socket.io-client";
import axios from "axios";



// components
import { Alert, Form, Input } from "reactstrap";
import StartButtons from "./ChatInputSection/StartButtons";
import InputSection from "./ChatInputSection/InputSection";
import EndButtons from "./ChatInputSection/EndButtons";
import MoreMenu from "./ChatInputSection/MoreMenu";
import Reply from "./ChatInputSection/Reply";
import { render } from "@testing-library/react";

//import SendIcon from '@mui/icons-material/Send';

const Conversation = ({  
  conversations,
  messages,
  params,
  newMessage,
  setNewMessage,
  arriavalMessage,
  onDelete,
  onSetReplyData,
  isChannel,
}) => { 

  const { userProfile } = useProfile();
  const { currentUser:user,selectedUserMessage } = useSelector((state) => state.user); 

  const dispatch = useDispatch();

  const chatUserConversations = [
    {messages: [     
        {
          mid: 1,
          text: 'Good morning 😊',
          time: '2022-05-20T09:01:49.055Z',
        }     
     ]      
     }
  ];

  //New code Start
  
  const socket = useRef();  
  const scrollRef = useRef(null);
console.log('conversationId',conversations)

const scrollToBottom = useCallback(() => {
  const listEle = document.getElementById("chat-conversation-list");
        let offsetHeight = 0;
        if (listEle) {
          offsetHeight = listEle.scrollHeight - window.innerHeight + 250;
          console.log('height',offsetHeight)
        }
        if (offsetHeight) {
          scrollRef.current
            //.getScrollElement()
            .scrollIntoView({ top: offsetHeight });
        }
  //scrollRef.current?.scrollIntoView({ behavior: "smooth" })
}, [scrollRef]);

useEffect(() => { 
  scrollToBottom()
}, [messages,scrollToBottom]);
 
  //New Code Close

  //old Code start
  const {getUserConversationsLoading, isMessageForwarded, chatUserDetails } = useSelector((state) => state.user);


  /*
  forward message
  */
  const [forwardData, setForwardData] = useState();
  const [isOpenForward, setIsOpenForward] = useState(false);
  const onOpenForward = (message) => {
    setForwardData(message);
    setIsOpenForward(true);
  };
  const onCloseForward = () => {
    setIsOpenForward(false);
  };

  const onForwardMessage = (data) => {
    const params = {
      contacts: data.contacts,
      message: data.message,
      forwardedMessage: forwardData,
    };
    dispatch(forwardMessage(params));
  };
  useEffect(() => {
    if (isMessageForwarded) {
      setIsOpenForward(false);
    }
  }, [isMessageForwarded]);

  /*
  image delete
  */
  const onDeleteImage = (
    messageId,
    imageId
  ) => {
    dispatch(deleteImage(chatUserDetails._id, messageId, imageId));
  };
  //old Code End


  {/* Form Input Code Start */}
         /*
  more menu collapse
  */
  const [isOpen, setIsOpen] = useState(false);
  const onToggle = () => {
    setIsOpen(!isOpen);
  };

  /*
  disable send button
  */
  const [disabled, setDisabled] = useState(true);

  /*
  input text
  */
  //const [text, setText] = useState(null);

  // const onChangeText = (value) => {
  //   setText(value);
  // };

  /*
  images
  */
  const [images, setImages] = useState();
  const onSelectImages = (images) => {
    setImages(images);
  };

  /*
  files
  */
  const [files, setFiles] = useState();
  const onSelectFiles = (files) => {
    setFiles(files);
  };

  // useEffect(() => {
  //   if (text || images || files) {
  //     setDisabled(false);
  //   } else {
  //     setDisabled(true);
  //   }
  // }, [text, images, files]);

  // const onSubmit = () => {
  //   let data = {};
  //   if (text) {
  //     data["text"] = text;
  //   }
  //   if (images && images.length) {
  //     const imgs = (images || []).map((i, key) => {
  //       const src = URL.createObjectURL(i);
  //       return {
  //         id: key + 1,
  //         downloadLink: src,
  //       };
  //     });
  //     data["image"] = imgs;
  //   }

  //   if (files && files.length) {
  //     const fs = (files || []).map((f, key) => {
  //       const src = URL.createObjectURL(f);
  //       return {
  //         id: key + 1,
  //         name: f.name,
  //         downloadLink: src,
  //         desc: f.size,
  //       };
  //     });
  //     data["attachments"] = fs;
  //   }

  //   setText("");
  //   setImages(null);
  //   setFiles(null);
  //   onSend(data);
  // };

  const onClearMedia = () => {
    setImages(null);
    setFiles(null);
  };
  {/* Form Input Code End */}



 

  return (
    <AppSimpleBar
      //scrollRef={ref}
     // ref={scrollRef}
      className="chat-conversation p-3 p-lg-4 positin-relative"
    >
      {getUserConversationsLoading && <Loader />}

      <ul
        className="list-unstyled chat-conversation-list"
        id="chat-conversation-list"
      >
        
        {(messages || []).map((message, key) => {  
         
          const isFromMe = message.sender + "" === user?._id + "";
          console.log('isFromMe',isFromMe)
          return (               
            <Message
              ref={scrollRef}
              message={message}
              params={params}
              key={key}
              chatUserDetails={chatUserDetails}
              onDelete={onDelete}
              onSetReplyData={onSetReplyData}
              isFromMe={isFromMe}
              onOpenForward={onOpenForward}
              isChannel={isChannel}
              onDeleteImage={onDeleteImage}
            />       
          );
        })}
        <div ref={scrollRef} />
        {/*  <Day /> */}
        
      </ul>
     

      {/* <ChatInputSection
        handleSubmit={handleSubmit}
        setNewMessage={setNewMessage}
        newMessage={newMessage}
        //onSend={onSend}
       // replyData={replyData}
        onSetReplyData={onSetReplyData}
        chatUserDetails={chatUserDetails}
      /> */}

     
      {/* FormInput Send Code Start  */} 

      {/* <ChatInputSection
      handleSubmit={handleSubmit}
      setNewMessage={setNewMessage}
      newMessage={newMessage}
      
        //onSend={onSend}
        //replyData={replyData}
        onSetReplyData={onSetReplyData}
        chatUserDetails={chatUserDetails}
      /> */}
      
      {/* <div className="chat-input-section p-3 p-lg-4">
      <Form
            id="chatinput-form"
            onSubmit={handleSubmit}
          >
        <div className="row g-0 align-items-center">
          <div className="col-auto">
            <StartButtons
              onToggle={onToggle}              
            />
          </div>
          <div className="col">           
            <Input
                type="text"
                className="form-control form-control-lg chat-input"
                id="chat-input"
                placeholder="Type your message..."
                autoComplete="off"
                onChange={e=>setNewMessage(e.target.value)}
                         value={newMessage} />
          </div>
          <div className="col-auto">           
            <EndButtons type="submit" variant="contained">Send</EndButtons>
          </div>
        </div>
      </Form>

      {(images && images.length) || (files && files.length) ? (
        <Alert
          isOpen={true}
          toggle={onClearMedia}
          color="secondary"
          className="alert-dismiss-custom 
        rounded-pill font-size-12 mb-1 selected-media"
          closeClassName="selected-media-close"
        >
          <p className="me-2 mb-0">
            {images && !files && ` You have selected ${images.length} images`}
            {files && !images && ` You have selected ${files.length} files`}
            {files &&
              images &&
              ` You have selected ${files.length} files & ${images.length} images.`}
          </p>
        </Alert>
      ) : null}

      <MoreMenu
        isOpen={isOpen}
        onSelectImages={onSelectImages}
        onSelectFiles={onSelectFiles}
        onToggle={onToggle}
      /> */}

      {/* <Reply
        reply={replyData}
        onSetReplyData={onSetReplyData}
        chatUserDetails={chatUserDetails}
      /> */}
      {/* </div> */}
        

       {/* FormInput Send Code End  */} 


      {isOpenForward && (
        <ForwardModal
          isOpen={isOpenForward}
          onClose={onCloseForward}
          forwardData={forwardData}
          chatUserDetails={chatUserDetails}
          onForward={onForwardMessage}
        />
      )}
    </AppSimpleBar>
  );
};

export default Conversation;
