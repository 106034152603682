import React, { useEffect, useState } from "react";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css"; // This only needs to be imported once in your app

// interface LightBoxProps {
//   isOpen: boolean;
//   onClose: () => any;
//   images: Array<any>;
//   defaultIdx?: number;
// }

const LightBox = ({ isOpen, onClose, defaultIdx, images }) => {

  console.log('lightbox',images)
  console.log('defaultIndex',defaultIdx)
  const [photoIndex, setPhotoIndex] = useState(0);
  useEffect(() => {
    if (defaultIdx) {
      setPhotoIndex(defaultIdx);
    }
  }, [defaultIdx]);

  const onPrev = () => {
    setPhotoIndex((photoIndex + images.length - 1) % images.length);
  };
  const onNext = () => {
    setPhotoIndex((photoIndex + 1) % images.length);
  };

  console.log('photoIndex',images[photoIndex])

  const URL = process.env.REACT_APP_PUBLIC_URL;

  return (
    <>
      {isOpen && (
        <Lightbox
          mainSrc={`${URL}`+`/${images[photoIndex]}`}
          nextSrc={`${URL}`+`/${images[(photoIndex + 1) % images.length]}`}
          prevSrc={`${URL}`+`/${images[(photoIndex + images.length - 1) % images.length]}`}
          onCloseRequest={onClose}
          onMovePrevRequest={onPrev}
          onMoveNextRequest={onNext}
        />
      )}
    </>
  );
};

export default LightBox;
