import React from 'react';
import { createSlice,createAsyncThunk } from "@reduxjs/toolkit";

const userSlice = createSlice({
  name: "user",
  initialState: {
    //layoutMode: 'light',
    layout: {
      chatUserId: null,
      activeTab: null,
      layoutMode: 'light',
      },
    currentUser: null,
    chatUserDetails: null,
    conversations: null,    
    isFetching: false,
    loginerror: false,
    communicate: false,
    communicateuserid: null,
   // loginAttempts:null,
    captcha:false,
    message:null,
    isLoggedIn:false,
    token:null,
    isUserLogin:false,
    loginLoading:false,
    loading:false,
    error:false,
    getProfileLoading:false,
    isProfileFetched:false,
    isContactInvited:false,
    getFavouritesLoading:false,
    isFavouritesFetched:false,
    isDirectMessagesFetched: false, 
    getDirectMessagesLoading: false,
    isChannelsFetched: false, 
    getChannelsLoading : false,
    isArchiveContactFetched : false,
    isContactsAdded: false,
    isChannelCreated: false,
    isContactArchiveToggled: false,
    isOpenUserDetails: false,
    selectedChat: null,
    selectedGroupChat: null,
    selectedUserMessage: null,
    getContactsLoading: false,
    isContactsFetched: false,
    getSettingsLoading: false,
    isSettingsFetched: false,
    isSettingsUpdated: false,
    isFavouriteContactToggled: false,
  },
  reducers: {
    loginStart: (state) => {
      state.isFetching = true;
      state.loading = true;
    },
    loginSuccess: (state, action) => {     
      state.isFetching = false;
      state.loginerror = false;
      state.loading = true;
      state.getProfileLoading = false;
      state.isProfileFetched = true;
      state.isContactInvited = true;  
      state.getFavouritesLoading = false; 
      state.isFavouritesFetched = true;  
      state.isDirectMessagesFetched =  true; 
      state.getDirectMessagesLoading = true;
      state.isChannelsFetched = true;
      state.getChannelsLoading = false;
      state.isArchiveContactFetched = true;     
      state.isContactsAdded = false;
      state.isChannelCreated = false;
      state.isContactArchiveToggled = false;
      state.isOpenUserDetails = false;
      state.selectedChat = null;
      state.selectedGroupChat = null;
      state.selectedUserMessage = null;
      state.getContactsLoading = false;
      state.isContactsFetched = true;
      state.getSettingsLoading = false;
      state.isSettingsFetched = true;
      state.isSettingsUpdated = false;
      state.isFavouriteContactToggled = true;
      state.currentUser = action.payload;
      state.token = null;    
     // state.loginAttempts = null;
      state.message = null;
      state.captcha = false;
      state.isLoggedIn=true;
      state.isUserLogin=true;
    },
    loginFailure: (state, action) => {
      state.isFetching = false;
      state.loginerror = true;
      state.loading = false;
      state.captcha = true;
     // state.error = true;
     // state.loginAttempts = action.payload.response.data.loginAttempts;
     // state.message = action.payload.response.data.message;
    },
    resetMessage:(state)=>{
      state.message = null;
      state.loginerror = false;
      state.captcha = false;
      state.isFetching = false;
    },
    logOut:(state)=>{
      state.selectedChat = null;
      state.selectedGroupChat = null;
      state.selectedUserMessage = null;
      state.currentUser = null;   
      state.isFetching = false;
      state.loginerror = false;
      state.communicate = false;
      state.communicateuserid = null;
     // state.loginAttempts = null;
      state.captcha = false;
      state.message  = null;
      state.isLoggedIn = false;
      state.loading = false;
      state.getProfileLoading = false;
      state.isProfileFetched = false;
      state.isContactInvited = false;
      state.token = null;
      state.chatUserDetails = null;
      state.isUserLogin = false;
      state.isChannelsFetched = false;
      state.loading = false;
      state.isDirectMessagesFetched = false;
      state.getDirectMessagesLoading = false;
      state.isArchiveContactFetched = false;
      state.isContactsFetched = false;
      state.isSettingsFetched = false;
      state.isFavouriteContactToggled = false;
      state.isFavouritesFetched = false;
    },
    layout: (state, action) => {
      state.layout.chatUserId = action.payload.chatUserId;
      state.layout.activeTab = action.payload.activeTab;
      state.layout.layoutMode = action.payload.layoutMode;      
    },
    changeLayoutmode: (state, action) => {
      //state.activeTab = action.payload;
      //state.layoutMode = action.payload;      
    },
    selectedChat: (state, action) => {
      state.selectedChat = action.payload;      
    },
    selectedGroupChat: (state, action) => {
      state.selectedGroupChat = action.payload;      
    },
    selectedUserMessage: (state, action) => {
      state.selectedUserMessage = action.payload;      
    },
    chatUserDetails: (state, action) => {
      state.chatUserDetails = action.payload;     
    },
    conversations: (state, action) => {
      state.conversations = action.payload;     
    },
    updateCurrentUser: (state, action) => {
      state.currentUser = action.payload;     
    },
    ssostatus: (state, action) => {
      state.isUserLogin = action.payload;     
    },
    communicate: (state, action) => {
      state.communicate = action.payload;     
    },
    communicateuserid: (state, action) => {
      state.communicateuserid = action.payload;     
    },
  },
});


export const { loginStart, loginSuccess, loginFailure, resetMessage, logOut,communicateuserid,communicate, changeLayoutmode,selectedChat,selectedGroupChat,selectedUserMessage,chatUserDetails,conversations,layout,updateCurrentUser,ssostatus} = userSlice.actions;
export const userReducer = userSlice.reducer;

const forgotPasswordSlice = createSlice({
  name: "forgotpassword",
  initialState: {
    isFetching:false,
    message:null,
    passwordError:false,
  },
  reducers: {
    loading: (state) => {
      state.isFetching = true;
    },
    verifyemail: (state, action) => {   
      state.currentUser = action.payload;
      state.passwordError = false;
    },
    incorrectemail: (state, action) => {
      state.passwordError = true;
      state.message = action.payload.response.data.message;
    },resetPwMessage:(state)=>{
      state.passwordError = false;
    },
  },
});


export const { loading, verifyemail, incorrectemail, resetPwMessage} = forgotPasswordSlice.actions;
export const forgotPassword = forgotPasswordSlice.reducer;