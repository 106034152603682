import React from "react";
import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

const cartSlice = createSlice({  
  name: "cart",
  initialState: {
    //cartItems: [],
    cartItems: [],
    cartTotalQuantity: 0,
    cartTotalAmount: 0,
    cartTotalItem: 0,
  },  
  reducers: {
    addToCart: (state, action) => {
      console.log("addToCart")
     const itemIndex =  state.cartItems.findIndex((item) => item._id === action.payload._id && item.color === action.payload.color && item.size === action.payload.size);
     if(itemIndex >= 0){
       state.cartItems[itemIndex].cartQuantity += 1;
       state.cartItems[itemIndex].color = action.payload.color;
       state.cartItems[itemIndex].size = action.payload.size;
       state.cartItems[itemIndex].sku = action.payload.sku;
       toast.info(`increased ${state.cartItems[itemIndex].title } product quantity`,{
         position: "top-right"
       })
     }
     else{
      const tempProduct = { ...action.payload, cartQuantity: 1};      
      state.cartItems.push(tempProduct);
      toast.success(`${action.payload.title} added in cart`,{
        position: "top-right"
      })
    }
    //For local Storage save cartItem data if you need but right now you are using presist:root process
    //localStorage.setItem("cartItems", JSON.stringify(state.cartItems));      
  },
  removeFromCart: (state,action) => {
    const nextCartItems = state.cartItems.filter(
      cartItem => cartItem.sku !== action.payload.sku
      );
      state.cartItems = nextCartItems;
      //if you want to remove cartItems from localstorage without using presist:root you can uncomment this line
      //localStorage.setItem("cartItems", JSON.stringify(state.cartItems));
      toast.error(`${action.payload.title} remove in cart`,{
        position: "top-right"
      })
    },
    decreaseCart(state, action){
      const itemIndex = state.cartItems.findIndex(
        cartItem => cartItem._id === action.payload._id 
      )
      if(state.cartItems[itemIndex].cartQuantity > 1){
        state.cartItems[itemIndex].cartQuantity -= 1
      } else if(state.cartItems[itemIndex].cartQuantity === 1){
              const nextCartItems = state.cartItems.filter(
                cartItem => cartItem.sku !== action.payload.sku 
                );
                state.cartItems = nextCartItems;
      }
      //if you want to decreese & increese cartItems from localstorage without using presist:root you can uncomment this line
      //localStorage.setItem("cartItems", JSON.stringify(state.cartItems));
    },
    clearCart: (state,action) => {
        state.cartItems = [];
        toast.error(`Remove Cart All Items`,{
          position: "top-right"
        });
        //if you want to remove cartItems from localstorage without using presist:root you can uncomment this line
      //localStorage.setItem("cartItems", JSON.stringify(state.cartItems));
    },
    cartSumTotal: (state,action) =>{
      let{ total, quantity } =  state.cartItems.reduce((cartTotal, cartItem)=>{
              const { price, cartQuantity } = cartItem;
              const itemTotal = price * cartQuantity;

              cartTotal.total += itemTotal
              cartTotal.quantity += cartQuantity             

              return cartTotal;
        }, {
          total: 0,
          quantity: 0,
          cartTotalItem: 0
        });        
        state.cartTotalQuantity = quantity;
        state.cartTotalAmount = total;        
    }    
  },
});



export const { addToCart,removeFromCart,decreaseCart,clearCart,cartSumTotal } = cartSlice.actions;
export default cartSlice.reducer;