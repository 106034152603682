import { ChatsActionTypes } from "./types";

// common success
export const chatsApiResponseSuccess = (actionType, data) => ({
  type: ChatsActionTypes.API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});
// common error
export const chatsApiResponseError = (actionType, error) => ({
  type: ChatsActionTypes.API_RESPONSE_ERROR,
  payload: { actionType, error },
});

export const getFavourites = () => ({
  type: ChatsActionTypes.GET_FAVOURITES,
});

export const getDirectMessages = () => ({
  type: ChatsActionTypes.GET_DIRECT_MESSAGES,
});

export const getChannels = () => ({
  type: ChatsActionTypes.GET_CHANNELS,
});

export const addContacts = (contacts) => ({
  type: ChatsActionTypes.ADD_CONTACTS,
  payload: contacts,
});

// export interface CreateChannelPostData {
//   name: string;
//   members: Array<string | number>;
//   description?: string;
// }
export const createChannel = (channelData) => ({
  type: ChatsActionTypes.CREATE_CHANNEL,
  payload: channelData,
});

export const changeSelectedChat = (selectedChat) => ({
  type: ChatsActionTypes.CHANGE_SELECTED_CHAT,
  payload: selectedChat,
});

export const getChatUserDetails = (selectedChat) => ({
  type: ChatsActionTypes.GET_CHAT_USER_DETAILS,
  payload: selectedChat,
});

export const getChatUserConversations = (
  selectedChat
) => ({
  type: ChatsActionTypes.GET_CHAT_USER_CONVERSATIONS,
  payload: selectedChat,
});

export const toggleUserDetailsTab = (value) => ({
  type: ChatsActionTypes.TOGGLE_USER_DETAILS_TAB,
  payload: value,
});

export const onSendMessage = (data) => ({
  type: ChatsActionTypes.ON_SEND_MESSAGE,
  payload: data,
});

export const receiveMessage = (id) => ({
  type: ChatsActionTypes.RECEIVE_MESSAGE,
  payload: id,
});

export const readMessage = (id) => ({
  type: ChatsActionTypes.READ_MESSAGE,
  payload: id,
});

export const receiveMessageFromUser = (id) => ({
  type: ChatsActionTypes.RECEIVE_MESSAGE_FROM_USER,
  payload: id,
});

export const deleteMessage = (
  userId,
  messageId
) => ({
  type: ChatsActionTypes.DELETE_MESSAGE,
  payload: { userId, messageId },
});

export const forwardMessage = (data) => ({
  type: ChatsActionTypes.FORWARD_MESSAGE,
  payload: data,
});

export const deleteUserMessages = (userId) => ({
  type: ChatsActionTypes.DELETE_USER_MESSAGES,
  payload: userId,
});

export const getChannelDetails = (id) => ({
  type: ChatsActionTypes.GET_CHANNEL_DETAILS,
  payload: id,
});

export const toggleFavouriteContact = (id) => ({
  type: ChatsActionTypes.TOGGLE_FAVOURITE_CONTACT,
  payload: id,
});

export const getArchiveContact = () => ({
  type: ChatsActionTypes.GET_ARCHIVE_CONTACT,
});

export const toggleArchiveContact = (id) => ({
  type: ChatsActionTypes.TOGGLE_ARCHIVE_CONTACT,
  payload: id,
});

export const readConversation = (id) => ({
  type: ChatsActionTypes.READ_CONVERSATION,
  payload: id,
});

export const deleteImage = (
  userId,
  messageId,
  imageId
) => ({
  type: ChatsActionTypes.DELETE_IMAGE,
  payload: { userId, messageId, imageId },
});
