import React, { useEffect, useState } from "react";

// hooks
//import { useRedux } from "../../../hooks/index";
import { useDispatch, useSelector } from "react-redux";

// components
import Loader from "../../../components/Loader";
import AppSimpleBar from "../../../components/AppSimpleBar";
import InviteContactModal from "../../../components/InviteContactModal";
import EmptyStateResult from "../../../components/EmptyStateResult";
import ListHeader from "./ListHeader";
import Contact from "./Contact";

// actions
import {
  getContacts,
  inviteContact,
  resetContacts,
  getChannelDetails,
  getChatUserDetails,
  getChatUserConversations,
  changeSelectedChat,
} from "../../../redux/actions";

import axios from "axios";

// import {
//   inviteContact,
//   resetContacts,
// } from "../../../redux/apiCalls";

//utils
import { divideByKey, DivideByKeyResultTypes } from "../../../utils";

// interface IndexProps {}

const Index = (props) => {
  // global store
 
  const dispatch = useDispatch();

  const { currentUser, getContactsLoading, isContactInvited } = useSelector((state) => state.user);

  /*
  get contacts
  */
  // useEffect(() => {
  //   dispatch(getContacts());
  // }, [dispatch]);

  const [contacts, setContacts] = useState([]);
  const [contactsData, setContactsData] = useState([]);

      
    const getFriendslist = async () => {
        const cUserId = currentUser._id;           
      const res = await axios.get("/users/allfriends/" + cUserId);
      setContacts(res);
    };
    
    useEffect(() => {
        getFriendslist();
    },[])

  // useEffect(() => {
  //   if (contacts.length > 0 ) {
  //     //getFriendslist();
  //     setContacts(contacts);
  //   }
  // }, []);

  useEffect(() => {
    if (contacts.length > 0) {
      const formattedContacts = divideByKey("username", contacts);
      setContactsData(formattedContacts);
    }    
  }, [contacts]);

  /*
  add contact modal handeling
  */
  const [isOpen, setIsOpen] = useState(false);
  const openModal = () => {
    setIsOpen(true);
  };
  const closeModal = () => {
    setIsOpen(false);
  };

  /*
  onInvite handeling
  */
  const onInviteContact = (data) => {
    dispatch(inviteContact(data));
  };

  useEffect(() => {
    if (isContactInvited) {
      setIsOpen(false);
      setTimeout(() => {
        dispatch(resetContacts("isContactInvited", false));
      }, 1000);
    }
  }, [dispatch, isContactInvited]);

  /*
  contact search
  */
  const [search, setSearch] = useState("");

  const onChangeSearch = (value) => {
        setSearch(value);
        let modifiedContacts = [...contacts];
        let filteredContacts = (modifiedContacts || []).filter((c) =>
          c["username"].toLowerCase().includes(value.toLowerCase())
        );
        setContacts(filteredContacts);
        if(value.length <= 0) {
        getFriendslist();
        }   
  };

  const totalC = (contacts || []).length;
  const onSelectChat = (id, isChannel) => {
    if (isChannel) {
      dispatch(getChannelDetails(id));
    } else {
      dispatch(getChatUserDetails(id));
    }
    dispatch(getChatUserConversations(id));
    dispatch(changeSelectedChat(id));
  };

  return (
    <>
      <div className="position-relative">
        {getContactsLoading && <Loader />}
        <ListHeader
          search={search}
          onChangeSearch={onChangeSearch}
          openModal={openModal}
        />

        <AppSimpleBar className="chat-message-list chat-group-list">
          <div>
            {totalC === 0 ? (
              <EmptyStateResult searchedText={search} />
            ) : (
              (contactsData || []).map(
                (letterContacts, key) => (
                  <Contact
                    letterContacts={letterContacts}
                    key={key}
                    index={key}
                    onSelectChat={onSelectChat}
                  />
                )
              )
            )}
          </div>
        </AppSimpleBar>
      </div>
      <InviteContactModal
        isOpen={isOpen}
        onClose={closeModal}
        onInvite={onInviteContact}
      />
    </>
  );
};

export default Index;
