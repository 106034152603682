import React, { useEffect,useState } from "react";

// hooks
//import { useRedux } from "../../../hooks/index";
import { useDispatch, useSelector } from "react-redux";

// components
import Loader from "../../../components/Loader";
import AppSimpleBar from "../../../components/AppSimpleBar";
import MyProfile from "./MyProfile";
import UserDescription from "./UserDescription";
import Media from "../../../components/Media";
import AttachedFiles from "../../../components/AttachedFiles";

// actions
import { getProfileDetails } from "../../../redux/actions";
import axios from 'axios';

// interface IndexProps {}

const Index = (props) => {
  // global store

  const {currentUser, getProfileLoading, isProfileFetched,layout } = useSelector((state) => state.user);

  const [ basicDetails , setBasicdetails ] = useState(currentUser);

  const getFriendProfile = async () => {
    try {
      if(layout.chatUserId !== null && layout.chatUserId !== undefined){
        const selectedChat = layout?.chatUserId;        
        const friendList = await axios.get("/users/friend/" + selectedChat);
        setBasicdetails(friendList);
      } 
      
    } catch (err) {
      console.log(err);
    }
  };

  const getCurrentProfile = async () => {
    try {
      if(layout.chatUserId === null){
        setBasicdetails(currentUser);
      } 
      
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if(layout.chatUserId !==null){
     // setPost(null);
      getFriendProfile();
     // getPostCount();
    }else if(layout.chatUserId === null){  
     // setPost(null);
      getCurrentProfile();
      //getPostCount();
    }
  },[layout.chatUserId])

  return (
    <div className="position-relative">
      {getProfileLoading && !isProfileFetched && <Loader />}
      <MyProfile basicDetails={basicDetails}/>

      <AppSimpleBar className="p-4 profile-desc">
        {/* <UserDescription /> */}
        <hr className="my-4" />

        {/* <Media media={friendProfile? friendProfile?.media : null} limit={2} /> */}

        <hr className="my-4" />

        {/* <AttachedFiles attachedFiles={friendProfile? friendProfile?.attachedFiles : null} /> */}
      </AppSimpleBar>
    </div>
  );
};

export default Index;
