import React, {useState,useEffect} from "react";
import { Link } from "react-router-dom";
import classnames from "classnames";

// interface
import { ChannelTypes } from "../../../data/chat";

import { useDispatch, useSelector } from "react-redux";
import { deleteGroupmember,changeSelectedTab,setselectedChat } from "../../../redux/apiCalls";
import { showSuccessNotification,showErrorNotification } from "../../../helpers/notifications";


import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import axios from "axios";
import UpdateDeleteChannel from "../../../components/UpdateDeleteChannel";

const ChatChannel = ({ channel,isSelectedChat,setSelectedChat, selectedChat, onSelectChat }) => {
  const unRead = channel.meta && channel.meta.unRead;

  const [isOpen, setIsOpen] = useState(false);

  const onOpen = () => {
    setIsOpen(true);
  };

  const onClose = () => {
    setIsOpen(false);
  };
  

  const { currentUser,layout } = useSelector((state) => state.user);

  const dispatch = useDispatch();

  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggle = () => setDropdownOpen(!dropdownOpen);

  const onClick = (groupId) => {
      console.log('setSelectedChatId',groupId)    
    setSelectedChat(groupId);
    const data = { groupid: groupId, userid: null }
    setselectedChat(dispatch, data);
    //onSelectChat(channel._id, true);
  };

  const reload = () => {
    const chatUserId = currentUser._id;
    const activeTab = 'pills-chat';
    const layoutMode = layout.layoutMode;
    const data = {chatUserId, activeTab , layoutMode }   
    changeSelectedTab(dispatch,data);
  }

  const onConfirm = async (data) => {
    const userId = currentUser._id;
    const admindata = { groupid: data.group, userid: userId };
    const res = await axios.post("/group/adminchannel", admindata);
    setIsOpen(false);
    showSuccessNotification('Group Channel Remove Successfully...');
    reload();
  }

  const ongroupClick = async (groupId) => {   
    const userId = currentUser._id;
    const data = { groupid: groupId, userid: userId };
    const res = await axios.post("/group/channel", data);
    if(res.message === true){
        setIsOpen(true);
    }else {
      showSuccessNotification('Group Channel Remove Successfully...');
      reload();
    }   
    
  };

  useEffect(() => {      
  },[layout.chatUserId])

  return (
    <>
    <li onClick={() => onClick(channel._id)}>
      <Link to="#" className={classnames({ "unread-msg-user": unRead })}>
        <div className="d-flex align-items-center">
          <div className="flex-shrink-0 avatar-xs me-2">
            <span className="avatar-title rounded-circle bg-soft-light text-dark">
              #
            </span>
          </div>
          <div className="flex-grow-1">
            <p className="text-truncate mb-0">{channel.name}</p>
          </div>
          <div className="flex-shrink-0">
          <Dropdown isOpen={dropdownOpen} toggle={toggle}>
            <DropdownToggle tag="a" className="text-mute">
              <i className="bx bx-dots-vertical-rounded align-middle"></i>
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-end">
            <DropdownItem
                className="d-flex align-items-center justify-content-between"
                onClick={() => ongroupClick(channel._id)}
              >
                Remove <i className="bx bx-trash ms-2 text-muted"></i>
              </DropdownItem>             
            </DropdownMenu>
            </Dropdown>
          </div>
          {unRead && unRead !== 0 ? (
            
            <div className="flex-shrink-0">
              <span className="badge badge-soft-dark rounded p-1">
                {unRead}
              </span>
            </div>
          ) : null}
        </div>
      </Link>
    </li>

    {isOpen && (
      <UpdateDeleteChannel
        isOpen={isOpen}
        onClose={onClose}
        onConfirm={onConfirm}
        group={channel}
      />
    )}
    </>    
  );
};

export default ChatChannel;
