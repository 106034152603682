import React, { useState, useEffect } from "react";
import { NavItem, UncontrolledTooltip, NavLink } from "reactstrap";

//constants
import { LAYOUT_MODES } from "../constants/index";

import { useDispatch, useSelector } from "react-redux";
//import { changelayoutMode } from "../../redux/actions";
//import {changelayoutMode} from "../../src/redux/apiCalls";

// interface LightDarkProps {
//   layoutMode: any;
//   onChangeLayoutMode: any;
// }

const LightDarkMode = ({ layoutMode }) => {

  const mode =
  layoutMode === LAYOUT_MODES["DARK"]
    ? LAYOUT_MODES["LIGHT"]
    : LAYOUT_MODES["DARK"];

    console.log("mode", mode)
    //console.log("onChangeLayoutMode",onChangeLayoutMode)
    console.log("layoutmode",layoutMode)

    const dispatch = useDispatch();

    const { selectedChat } = useSelector((state) => state.user);

    const [isOpen, setIsOpen] = useState('dark');

    useEffect(() => {
      document.body.setAttribute('data-layout-mode',`${isOpen}`);      
    },[isOpen])

    const onClick = () => {
      if(isOpen === 'light')
      {
        const dark = 'dark';
        setIsOpen(dark)
      } else if(isOpen === 'dark'){
        const light = 'light';
        setIsOpen(light)
      }
    };
    
  return (
    <NavItem className="mt-auto" id="color-mode">
      
      <NavLink
        className="nav-link light-dark"
        onClick={()=> onClick()}
      >
        <i className="bx bx-moon" id="moon"></i>{" "}
      </NavLink>{" "}
      <UncontrolledTooltip placement="right" target="color-mode">
        <span className="light-mode">Light</span>
        <span className="dark-mode">Dark</span> Mode{" "}
      </UncontrolledTooltip>{" "} 
    </NavItem>
  );
};

export default LightDarkMode;
