import React, { useEffect, useState } from "react";
import classnames from "classnames";

import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  InputGroup,
} from "reactstrap";

// hooks
//import { useRedux } from "../hooks/index";
import { useDispatch, useSelector } from "react-redux";

// components
import AppSimpleBar from "./AppSimpleBar";
import EmptyStateResult from "./EmptyStateResult";

//utils
import { DivideByKeyResultTypes, divideByKey } from "../utils";

// interfaaces
import { ContactTypes } from "../data/contacts";

// hooks
import { useContacts } from "../hooks";


import axios from "axios";

// interface ContactItemProps {
//   contact: ContactTypes;
//   selected: boolean;
//   onSelectContact: (id: string | number, selected: boolean) => void;
// }

const ContactItem = ({
  contact,
  selected,
  onSelectContact,
}) => {
  //const fullName = `${contact.username} ${contact.lastName}`;
  console.log('contact',contact)
  const fullName = `${contact.username}`;
  const onClick = () => {
    onSelectContact(contact._id, !selected);
  };

  return (
    <li className={classnames({ selected: selected })} onClick={onClick}>
      <div>
        <h5 className="font-size-14 m-0">{fullName}</h5>
      </div>
    </li>
  );
};

const CharacterItem = ({
  letterContacts,
  index,
  //totalContacts,
  selectedContacts,
  onSelectContact,
}) => {
  return (
    <div className={classnames({ "mt-3": index !== 0 })}>
      <div className="contact-list-title">{letterContacts.letter}</div>

      <ul
        className={classnames("list-unstyled", "contact-list", {
          "mb-0": index + 1 === 3,
        })}
      >
        {(letterContacts.data || []).map((contact, key) => {
          console.log('letterContacts',letterContacts)
          console.log('conatId',contact._id)
          console.log('selectedContacts',selectedContacts)
          const selected = selectedContacts.includes(contact._id);
          console.log('selected',selected)
          return (
            <ContactItem
              contact={contact}
              key={key}
              selected={selected}
              onSelectContact={onSelectContact}
            />
          );
        })}
      </ul>
    </div>
  );
};

const ContactModal = ({ isOpen, onClose, onAddContact }) => {
  // global store
  
  const { currentUser } = useSelector((state) => state.user);
  /*
  contacts hook
  */
  // const { categorizedContacts, totalContacts } = useContacts();
  // const [contacts, setContacts] = useState([]);

  const [contacts, setContacts] = useState([]);
  const [contactsData, setContactsData] = useState([]);

  const getFriendslist = async () => {
    const cUserId = currentUser._id;           
  const res = await axios.get("/users/allfriends/" + cUserId);
  setContacts(res);
};

useEffect(() => {
    getFriendslist();
},[])

  // useEffect(() => {
  //   setContacts(categorizedContacts);
  // }, [categorizedContacts]);

   useEffect(() => {
    if (contacts.length > 0) {
      const formattedContacts = divideByKey("username", contacts);
      setContactsData(formattedContacts);
    }    
  }, [contacts]);

  /*
  contact search
  */
  const [search, setSearch] = useState("");

  const onChangeSearch = (value) => {
        setSearch(value);
        let modifiedContacts = [...contacts];
        let filteredContacts = (modifiedContacts || []).filter((c) =>
          c["username"].toLowerCase().includes(value.toLowerCase())
        );
        setContacts(filteredContacts);
        if(value.length <= 0) {
        getFriendslist();
        }   
  };

  const totalC = (contacts || []).length;

  /*
  select contacts
  */

  const [selectedContacts, setSelectedContacts] = useState([]);
  const onSelectContact = (id, selected) => {

    console.log('onSelectContactId',id);
    console.log('onSelectContact',selected)
    let modifiedList = [...selectedContacts];
    if (selected) {
      modifiedList = [...modifiedList, id];
    } else {
      modifiedList = modifiedList.filter(m => m + "" !== id + "");
    }
    setSelectedContacts(modifiedList);
  };

 

  /*
  disale button
  */
  const disabled = selectedContacts.length === 0;

  /*
  submit data
  */
  const onSubmit = () => {
    onAddContact(selectedContacts);
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={onClose}
      tabIndex={-1}
      centered
      className="contactModal"
    >
      <ModalHeader className="modal-title-custom" toggle={onClose}>
        Add Contacts
      </ModalHeader>
      <ModalBody className="p-4">
        <InputGroup className="mb-4">
          <Input
            type="text"
            className="form-control bg-light border-0 pe-0"
            placeholder="Search here.."
            aria-label="Example text with button addon"
            aria-describedby="contactSearchbtn-addon"
            value={search || ""}
            onChange={(e) => {
              onChangeSearch(e.target.value);
            }}
          />
          <Button color="light" type="button" id="contactSearchbtn-addon">
            <i className="bx bx-search align-middle"></i>
          </Button>
        </InputGroup>

        {totalC === 0 ? (
          <EmptyStateResult searchedText={search} />
        ) : (
          <>
            <div className="d-flex align-items-center px-1">
              <div className="flex-grow-1">
                <h4 className=" font-size-11 text-muted text-uppercase">
                  Contacts
                </h4>
              </div>
            </div>
            <AppSimpleBar
              className="contact-modal-list mx-n4 px-1"
              style={{ maxHeight: "200px" }}
            >
              {(contactsData || []).map(
                (letterContacts, key) => (
                  <CharacterItem
                    letterContacts={letterContacts}
                    key={key}
                    index={key}
                   // totalContacts={totalContacts}
                    selectedContacts={selectedContacts}
                    onSelectContact={onSelectContact}
                  />
                )
              )}
            </AppSimpleBar>
          </>
        )}
      </ModalBody>
      <ModalFooter>
        <Button type="button" color="link" onClick={onClose}>
          Cancel
        </Button>
        <Button
          type="button"
          color="primary"
          disabled={disabled}
          onClick={onSubmit}
        >
          <i className="bx bxs-send align-middle"></i>
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ContactModal;
