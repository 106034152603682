import { BookmarksActionTypes } from "./types";

// common success
export const bookmarksApiResponseSuccess = (actionType, data) => ({
  type: BookmarksActionTypes.API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});
// common error
export const bookmarksApiResponseError = (
  actionType,
  error
) => ({
  type: BookmarksActionTypes.API_RESPONSE_ERROR,
  payload: { actionType, error },
});

export const getBookmarks = () => ({
  type: BookmarksActionTypes.GET_BOOKMARKS,
});

export const updateBookmark = (id, data) => ({
  type: BookmarksActionTypes.UPDATE_BOOKMARK,
  payload: { id, data },
});

export const deleteBookmark = (id) => ({
  type: BookmarksActionTypes.DELETE_BOOKMARK,
  payload: id,
});

export const resetBookmarks = (flag, value) => ({
  type: BookmarksActionTypes.RESET_BOOKMARKS,
  payload: { flag, value },
});
