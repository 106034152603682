import React, { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import classnames from "classnames";
import styled from 'styled-components';

// hooks
//import { useRedux } from "../../hooks/index";
import { useDispatch, useSelector } from "react-redux";
import { changeSelectedChat,changeChatUserDetails,changeSelectedTab } from "../../redux/apiCalls";
import { changelayoutMode } from "../../redux/actions";

// hooks
import { useConversationUserType } from "../../hooks/index";

// component
import Leftbar from "./Leftbar";
import ConversationUser from "./ConversationUser/index";
import UserProfileDetails from "./UserProfileDetails/index";
import Welcome from "./ConversationUser/Welcome";
import SideMenu from "../../layouts/Default/index";
import { TABS } from "../../constants/index";

import axios from "axios";

const MobileView = styled.div`
  display: flex; 
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;


// interface IndexProps {}
const Index = (props) => {
  // global store
 // const { useAppSelector } = useRedux();

  // const { selectedChat } = useAppSelector(state => ({
  //   selectedChat: state.Chats.selectedChat,
  // }));

  const dispatch = useDispatch();

  const { selectedChat,isUserLogin,layout,communicateuserid } = useSelector((state) => state.user);

  const { isChannel } = useConversationUserType();

  useEffect(() => {
    const activeTab = 'pills-chat';
    const layoutMode = layout.layoutMode;
   const data = { activeTab , layoutMode }   
    changeSelectedTab(dispatch,data);
    console.log('Dashboard');
  },[])
  

  /*
  call dark/light mode
  */
  const onChangeLayoutMode = (value) => {
    if (changelayoutMode) {
      dispatch(changelayoutMode(value));
    }
  };
  
  useEffect(() => {
    changeSelectedChat(dispatch,null);
    changeChatUserDetails(dispatch,null);
  },[])

   if (communicateuserid == null && isUserLogin === false) {
    return <Redirect to="/auth-login" />;
  }  

  return (
    <>
    <div style={{display: 'flax'}}>
    <MobileView >
      <div className="layout-wrapper d-lg-flex">
      <SideMenu onChangeLayoutMode={onChangeLayoutMode}/>
      
      </div>
      <div>
      <Leftbar />
      </div>

      <div
        className={classnames("user-chat", "w-100", "overflow-hidden", {
          "user-chat-show": selectedChat,
        })}
        id="user-chat"
      >
        <div className="user-chat-overlay" id="user-chat-overlay"></div>
        {selectedChat !== null ? (
          <div className="chat-content d-lg-flex">
            <div className="w-100 overflow-hidden position-relative">
              <ConversationUser isChannel={isChannel} />
            </div>
            {/* <UserProfileDetails isChannel={isChannel} /> */}
          </div>
        ) : (
          <Welcome />
        )}
      </div>
      </MobileView>
      </div>   
      
    </>
  );
};

export default Index;
