import React, { useEffect, useState } from "react";

import { Button, Form, UncontrolledTooltip } from "reactstrap";
import { Link } from "react-router-dom";
// hooks
//import { useRedux } from "../../../hooks/index";
import { useDispatch, useSelector } from "react-redux";

// actions
import {
  inviteContact,
  resetContacts,
  getFavourites,
  getDirectMessages,
  getChannels,
  addContacts,
  createChannel,
  changeSelectedChat,
  getChatUserDetails,
  getChatUserConversations,
  getChannelDetails,
  getArchiveContact,
  readConversation,
} from "../../../redux/actions";

// API Calls
import { createGroup, changeSelectedTab } from "../../../redux/apiCalls";
import { showSuccessNotification,showErrorNotification } from "../../../helpers/notifications";

import ExitToAppOutlinedIcon from '@mui/icons-material/ExitToAppOutlined';
import { divideByKey, DivideByKeyResultTypes } from "../../../utils";

// components
import AppSimpleBar from "../../../components/AppSimpleBar";
import AddGroupModal from "../../../components/AddGroupModal";
import InviteContactModal from "../../../components/InviteContactModal";
import AddButton from "../../../components/AddButton";
import ContactModal from "../../../components/ContactModal";

import Favourites from "./Favourites";
import YoutubeChannel from "./YoutubeChannel";
import DirectMessages from "./DirectMessages";
import Chanels from "./Chanels";
import Archive from "./Archive";
import { CHATS_TABS, DisplayTypes } from "../../../constants";
import axios from "axios";
import { communicateuserid, logOut } from "../../../redux/userRedux";

// interface IndexProps {}

const Index = (props) => {
  // global store

  const dispatch = useDispatch();

  const { 
    currentUser,
    isContactInvited,
    isFavouriteContactToggled,
    isContactsAdded,
    isChannelCreated,
    isContactArchiveToggled,   
    //favourites,
    selectedChat,
    //directMessages,
    //channels,
    archiveContacts,
    layout,
    communicate, 
    communicateuserid, 
   } = useSelector((state) => state.user);

  /*
  get data
  */

  const [favourites, setContacts] = useState([]);
  const [youtubers, setYoutubers] = useState([]);
  const [frequest, setFriendrequest] = useState();
  const [contactsData, setContactsData] = useState([]);  

  const [selectedChatId, setSelectedChat] = useState({});
  const [selectedYoutubeChatId, setSelectedYoutubeChat] = useState({});
  const [channels, setChannels] = useState([]);

  
    const getFriendslist = async () => {
      try {
        const cUserId = currentUser._id; 
        const friendList = await axios.get("/users/friends/" + cUserId);
        setContacts(friendList);
        console.log("favourites",favourites)
      } catch (err) {
        console.log(err);
      }
    };

    const getYoutuberslist = async () => {
      try {
        const cUserId = currentUser._id; 
        const youtuberList = await axios.get("/users/youtubers/" + cUserId);
        setYoutubers(youtuberList);
        console.log("youtuberList",youtuberList);
      } catch (err) {
        console.log(err);
      }
    };

    const getChannellist = async () => {
      try {
        const cUserId = currentUser._id; 
        const channelList = await axios.get("/users/channel/" + cUserId);
        setChannels(channelList);
        console.log("channels",channels)
      } catch (err) {
        console.log(err);
      }
    };

    useEffect(() => {
    getFriendslist();
    getYoutuberslist();
    getChannellist();
      }, [layout.chatUserId]);

      console.log('layoutChatuserId',layout)

  //Search User Code Start
  useEffect(() => {
    if (favourites.length > 0) {
      const formattedContacts = divideByKey("username", favourites);
      setContactsData(formattedContacts);
    }    
  }, [favourites]);

    //My Friends List User List Search Code Start
    const [search, setSearch] = useState("");

    const onChangeSearch = (value) => {
          setSearch(value);
          let modifiedContacts = [...favourites];
          let filteredContacts = (modifiedContacts || []).filter((c) =>
            c["username"].toLowerCase().includes(value.toLowerCase())
          );
          setContacts(filteredContacts);
          if(value.length <= 0) {
          getFriendslist();
          }   
    };
  
    const totalC = (favourites || []).length;
    //My Friends List User List Search Code End

  //Search user Code End

  //Friend Request Users List Code Start
  useEffect(() => {        
    const getFriendsRequestlist = async () => {           
      const res = await axios.get("/users/friendsRequest/" + currentUser._id);        
      setFriendrequest(res);
    };    
    getFriendsRequestlist();
  }, []);

  console.log('frequest',frequest)
  //Friend Request Users List Code End
  


  useEffect(() => {
    dispatch(getFavourites());
    dispatch(getDirectMessages());
    dispatch(getChannels());
  }, [dispatch]);

  useEffect(() => {
    if (isFavouriteContactToggled) {
      dispatch(getFavourites());
      dispatch(getDirectMessages());
    }
  }, [dispatch, isFavouriteContactToggled]);

  /*
  invite contact modal handeling
  */
  const [isOpen, setIsOpen] = useState(false);
  const openModal = () => {
    setIsOpen(true);
  };
  const closeModal = () => {
    setIsOpen(false);
  };

  /*
  onInvite handeling
  */
  const onInviteContact = (data) => {
    dispatch(inviteContact(data));
  };
  useEffect(() => {
    if (isContactInvited) {
      setIsOpen(false);
      setTimeout(() => {
        dispatch(resetContacts("isContactInvited", false));
      }, 1000);
    }
  }, [dispatch, isContactInvited]);

  /*
  contact add handeling
  */
  const [isOpenAddContact, setIsOpenAddContact] = useState(false);
  const openAddContactModal = () => {
    setIsOpenAddContact(true);
  };
  const closeAddContactModal = () => {
    setIsOpenAddContact(false);
  };
  const onAddContact = (contacts) => {
    console.log('ContactData',contacts)
    dispatch(addContacts(contacts));
  };
  useEffect(() => {
    if (isContactsAdded) {
      setIsOpenAddContact(false);
      dispatch(getDirectMessages());
    }
  }, [dispatch, isContactsAdded]);

  /*
  channel creation handeling
  */
  const [isOpenCreateChannel, setIsOpenCreateChannel] =
    useState(false);

  const openCreateChannelModal = () => {
    setIsOpenCreateChannel(true);
  };
  const closeCreateChannelModal = () => {
    setIsOpenCreateChannel(false);
  };

  const onCreateChannel = (channelData) => {  
    console.log('channelData',channelData)  
     createGroup(dispatch,channelData);
     showSuccessNotification('Group Created Successfully...');
     closeCreateChannelModal();

     const chatUserId = channelData.createdBy;
     const activeTab = 'pills-chat';
     const layoutMode = layout.layoutMode;
     const data = {chatUserId, activeTab , layoutMode }   
     changeSelectedTab(dispatch,data);
  };

  useEffect(() => {
    if (isChannelCreated) {
      setIsOpenCreateChannel(false);
      dispatch(getChannels());
    }
  }, [dispatch, isChannelCreated]);

  const handleCommunicate = () =>{
      dispatch(logOut());
      const redirecturl =  process.env.REACT_APP_CORS_DOMAIN;
      window.location.href = `${redirecturl}/dashboard`;
  }

  /*
  select chat handeling :
    get conversations
    get chat user details
  */

  const onSelectChat = (id, isChannel) => {
    if (isChannel) {
      dispatch(getChannelDetails(id));
    } else {
      dispatch(getChatUserDetails(id));
    }
    dispatch(readConversation(id));
    dispatch(getChatUserConversations(id));
    dispatch(changeSelectedChat(id));
  };

  /*
  tab handeling
  */
  const [active, setActive] = useState(CHATS_TABS.DEFAULT);
  const onChangeTab = (tab) => {
    setActive(tab);
  };

  /*
  archive contacts
  */
  useEffect(() => {
    dispatch(getArchiveContact());
  }, [dispatch]);
  useEffect(() => {
    if (isContactArchiveToggled) {
      dispatch(getArchiveContact());
      dispatch(getFavourites());
      dispatch(getDirectMessages());
      dispatch(getChannels());
      dispatch(getChatUserDetails(currentUser._id));
    }
  }, [dispatch, isContactArchiveToggled, currentUser._id]);

  return (
    <>
      <div>
        <div className="px-4 pt-4">
          <div className="d-flex align-items-start">
            <div className="flex-grow-1">
              <h4 className="mb-4">Chats</h4>
            </div>
            <div className="flex-shrink-0">
              <div id="add-contact">
                {/* Button trigger modal */}
                <AddButton onClick={openModal} />
              </div>
              <UncontrolledTooltip target="add-contact" placement="bottom">
                Add Contact
              </UncontrolledTooltip>
            </div>
          </div>
          <Form>
            <div className="input-group mb-3">
              <input
                type="text"
                className="form-control bg-light border-0 pe-0"
                placeholder="Search here.."
                aria-label="Example text with button addon"
                aria-describedby="searchbtn-addon"
                value={search || ""}
                onChange={(e) => onChangeSearch(e.target.value)}
              />
              <Button
                className="btn btn-light"
                type="button"
                id="searchbtn-addon"
              >
                <i className="bx bx-search align-middle"></i>
              </Button>
            </div>
          </Form>
        </div>{" "}
        {/* .p-4 */}
        <AppSimpleBar className="chat-room-list">
          {/* Start chat-message-list */}
          {active === CHATS_TABS.DEFAULT && (
            <>
              {/* favourite */}
              { communicateuserid === null?
              (<Favourites
                users={favourites}
                selectedChat={selectedChatId}
                setSelectedChat={setSelectedChat}
                onSelectChat={onSelectChat}
              />) : "" } 

             { communicateuserid !== null?
              (<YoutubeChannel
                users={youtubers}
                selectedChat={selectedYoutubeChatId}
                setSelectedChat={setSelectedYoutubeChat}
                onSelectChat={onSelectChat}
                youtube={currentUser?.youtube}
              />) : "" }

              {/* direct messages */}
              <DirectMessages
                users={frequest}
                openAddContact={openAddContactModal}
                setSelectedChat={setSelectedChat}
                selectedChat={selectedChatId}
                onSelectChat={onSelectChat}
              />

              {/* channels list */}
              <Chanels
                channels={channels}
                openCreateChannel={openCreateChannelModal}
                selectedChat={selectedChatId}
                setSelectedChat={setSelectedChat}
                onSelectChat={onSelectChat}
              />
              <h5 className="text-center mb-2">
                {
                  communicate === true?               
                (
                  <Link
                  to="#"
                  className="mb-3 px-4 mt-4 font-size-11 text-primary"
                  onClick={handleCommunicate}
                >
                  Go To OSSTUBE 
                  <ExitToAppOutlinedIcon />
                </Link>
                ) :(<Link
                  to="#"
                  className="mb-3 px-4 mt-4 font-size-11 text-primary"
                  onClick={() => onChangeTab(CHATS_TABS.ARCHIVE)}
                >
                  Archived Contacts{" "}
                  <i className="bx bxs-archive-in align-middle" />
                </Link>) }
              </h5>
            </>
          )}
          {active === CHATS_TABS.ARCHIVE && (
            <>
              <Archive
                archiveContacts={archiveContacts}
                selectedChat={selectedChat}
                onSelectChat={onSelectChat}
              />
              <h5 className="text-center mb-2">
                <Link
                  to="#"
                  className="mb-3 px-4 mt-4 font-size-11 text-primary"
                  onClick={() => onChangeTab(CHATS_TABS.DEFAULT)}
                >
                  Chats <i className="bx bxs-archive-out align-middle" />
                </Link>
              </h5>
            </>
          )}

          {/* End chat-message-list */}
        </AppSimpleBar>
      </div>
      {/* add group Modal */}
      {isOpenCreateChannel && (
        <AddGroupModal
          isOpen={isOpenCreateChannel}
          onClose={closeCreateChannelModal}
          onCreateChannel={onCreateChannel}
        />
      )}

      {/* add contact modal */}
      {isOpen && (
        <InviteContactModal
          isOpen={isOpen}
          onClose={closeModal}
          onInvite={onInviteContact}
        />
      )}

      {isOpenAddContact && (
        <ContactModal
          isOpen={isOpenAddContact}
          onClose={closeAddContactModal}
          onAddContact={onAddContact}
        />
      )}
    </>
  );
};

export default Index;
