import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import classnames from "classnames";

import { useDispatch, useSelector } from "react-redux";

// interface
import { UserTypes } from "../../../data/chat";
import { STATUS_TYPES } from "../../../constants";

// interface ChatUserProps {
//   user: UserTypes;
//   selectedChat: string | number;
//   onSelectChat: (id: number | string) => void;
// }

const YoutubeChatuser = ({ youtube, user, selectedChat, onSelectChat }) => {

  const { communicateuserid } = useSelector((state) => state.user);

  console.log('youtubedata',youtube)

  const fullName = youtube === true? `${user.firstname} ${user.lastname}` : `${user.owner}` ;
  const shortName = `${user.username.charAt(0)}`;

 const userData = {...user};

  console.log("fullName",fullName)
  

  const colors = [
    "bg-primary",
    "bg-danger",
    "bg-info",
    "bg-warning",
    "bg-secondary",
    "bg-pink",
    "bg-purple",
  ];
  const [color] = useState(Math.floor(Math.random() * colors.length));
  const isOnline = userData.status && userData.status === STATUS_TYPES.ACTIVE;
  const unRead = userData.meta && userData.meta.unRead;

  const isSelectedChat =
    selectedChat && selectedChat === userData._id ? true : false;

    const liRef = useRef(null);

    useEffect(() => {
      console.log('dataId',liRef.current.id);
      if (liRef.current && liRef.current.id === communicateuserid) {
        liRef.current.click();        
      }
    }, []);



  const onClick = () => {
    onSelectChat(userData._id);
  };
  return (
    <li className={classnames({ active: isSelectedChat })}  key={userData._id} ref={liRef} id={userData._id} onClick={() => onClick(userData._id)}>
      <Link to="#" className={classnames({ "unread-msg-user": unRead })}>
        <div className="d-flex align-items-center">
          <div
            className={classnames(
              "chat-user-img",
              "align-self-center",
              "me-2",
              "ms-0",
              { online: isOnline }
            )}
          >
            {userData.profileImage ? (
              <>
                <img
                  src={userData.profileImage}
                  className="rounded-circle avatar-xs"
                  alt=""
                />
                {isOnline && <span className="user-status"></span>}
              </>
            ) : (
              <div className="avatar-xs">
                <span
                  className={classnames(
                    "avatar-title",
                    "rounded-circle",
                    "text-uppercase",
                    "text-white",
                    colors[color]
                  )}
                >
                  <span className="username"> {shortName}</span>
                  <span className="user-status"></span>
                </span>
              </div>
            )}
          </div>
          <div className="overflow-hidden">
            <p className="text-truncate mb-0">{fullName}</p>
          </div>
          {unRead && unRead !== 0 ? (
            <div className="ms-auto">
              <span className="badge badge-soft-dark rounded p-1">
                {unRead}
              </span>
            </div>
          ) : null}
        </div>
      </Link>
    </li>
  );
};

export default YoutubeChatuser;
