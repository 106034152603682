import React from "react";

// interface
import { MessagesTypes } from "../../../data/messages";

// hooks
import { useProfile } from "../../../hooks";
import { useDispatch,useSelector } from "react-redux";

// interface RepliedMessageProps {
//   isFromMe: boolean;
//   message: MessagesTypes;
//   fullName: string;
// }

function RepliedMessage({ isFromMe,params, message, fullName }) {
  const { userProfile } = useProfile();

  const { currentUser } = useSelector((state) => state.user);

  const isReplyFromMe = message.sender + "" === currentUser._id + "";
  return (
    <>
    <div className="">
      <div className="replymessage-block mb-2 d-flex align-items-start">
        <div className="flex-grow-1">
          <h5 className="conversation-name">
            {isReplyFromMe ? "You" : fullName}
          </h5>
          {message?.replyOf && (
            <p className="mb-0">{message.replyOf}</p>
          )}          
          {(message.replyOf?.image || message.replyOf?.attachments) && (
            <p className="mb-0">
              {message.replyOf?.attachments &&
                !message.replyOf?.image &&
                `${message.replyOf?.attachments.length} Files`}
              {message.replyOf?.image &&
                !message.replyOf?.attachments &&
                `${message.replyOf?.image.length} Images`}
              {message.replyOf?.image &&
                message.replyOf?.attachments &&
                `${message.replyOf?.attachments.length} Files & ${message.replyOf?.image.length} Images`}
            </p>
          )}
        </div>         
      </div>
    </div>
    {message?.text && (
      <p className="mb-0">{message?.text}</p>
      )}         
      </>
  );
}

export default RepliedMessage;
