import React, { useState, useEffect } from "react";

// hooks
import { useRedux } from "./index";
import { useDispatch, useSelector } from "react-redux";
import { setchatUserDetails } from "../redux/apiCalls";


// api
import { getLoggedinUser } from "../api/apiCore";

//utils
import { divideByKey } from "../utils";

import axios from "axios";

const useProfile = () => {
  // global store
  //const { useAppSelector } = useRedux();

  // const { settings } = useAppSelector(state => ({
  //   settings: state.Settings.settings,
  // }));

  // const { currentUser } =
  //     useSelector(state => ({
  //       settings: state.Settings.settings,
  //     }));

  const dispatch = useDispatch();

  const { currentUser, isLoggedIn,selectedChat } = useSelector((state) => state.user);

  console.log("islogedIn",isLoggedIn);
  console.log("currentUser",currentUser)
  console.log('selectedChat',selectedChat) 

  
  
 

  //const image = settings.basicDetails && settings.basicDetails.profile;
  const image = currentUser? currentUser.firstname+''+currentUser.lastname : null && currentUser?.profilePicture;
  //const userProfileSession = getLoggedinUser();
  const [loading] = useState(isLoggedIn ? false : true);
  const [userProfile, setUserProfile] = useState(
    isLoggedIn ? { ...isLoggedIn, profileImage: image } : null
  );
  useEffect(() => {
   // const userProfileSession = getLoggedinUser();
    setUserProfile(
      isLoggedIn ? { ...isLoggedIn, profileImage: image } : null
    );
  }, []);

  return { userProfile, loading };
};

const useContacts = () => {
  // global store
  //const { useAppSelector } = useRedux();

  // const { contactsList } = useAppSelector(state => ({
  //   contactsList: state.Contacts.contacts,
  // }));

  const { currentUser } = useSelector((state) => state.user);

  const [contacts, setContacts] = useState([]);
  const [categorizedContacts, setCategorizedContacts] = useState([]);

  useEffect(() => {
    const getFriendslist = async () => {
      try {
        const cUserId = currentUser._id; 
        const friendList = await axios.get("/users/friends/" + cUserId);
        setContacts(friendList.data);
      } catch (err) {
        console.log(err);
      }
    };
    getFriendslist();
  }, []);

  // useEffect(() => {        
  //   const getFriendslist = async () => {
  //       const cUserId = currentUser._id;           
  //     const res = await axios.get("/users/allfriends/" + cUserId);
  //     setContacts(res);
  //   };    
  //   getFriendslist();
  // }, [contacts]);

  // useEffect(() => {
  //   if (contactsList.length > 0) {
  //     setContacts(contactsList);
  //   }
  // }, [contactsList]);

  useEffect(() => {
    if (contacts.length > 0) {
      const formattedContacts = divideByKey("username", contacts);
      setCategorizedContacts(formattedContacts);
    }
  }, []);

  const totalContacts = (contacts || []).length;

  return {contacts, categorizedContacts, totalContacts };
};

const useConversationUserType = () => {
  // global store
  //const { useAppSelector } = useRedux();

  // const { chatUserDetails } = useAppSelector(state => ({
  //   chatUserDetails: state.Chats.chatUserDetails,
  // }));

  const { chatUserDetails } = useSelector((state) => state.user);

  const [isChannel, setIsChannel] = useState(false);
  useEffect(() => {
    setIsChannel(chatUserDetails?.isChannel ? true : false);
  }, []);

  return { isChannel };
};
export { useProfile, useContacts, useConversationUserType };
