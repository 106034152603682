import React, { useState, useEffect } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap";

const UpdateDeleteChannel = ({
  isOpen,
  onClose,
  onConfirm,
  group,  
}) => {
  
  const [data, setData] = useState({ group: group._id });

  const [valid, setValid] = useState(false);
  /*
    submit data
    */
  const onSubmit = () => {
      console.log('onUpdate',data)
    onConfirm(data);
  };
  return (
    <Modal isOpen={isOpen} toggle={onClose}>
      <ModalHeader className="modal-title-custom" toggle={onClose}>
        Channel Group
      </ModalHeader>
      <ModalBody>
        <Form>
          <FormGroup>
            <Label htmlFor="update-bookmark" className="mb-2">
              You are admin for this group. Do you really want to delete this group, Please
              make sure firstly you will not be able to recover this group after deletion....!
            </Label>
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button type="button" color="none" onClick={onClose}>
          Cancel
        </Button>
        <Button color="primary" onClick={onSubmit}>
          Confirm
        </Button>{" "}
      </ModalFooter>
    </Modal>
  );
};

export default UpdateDeleteChannel;
